// 电商管理
import axios from '@/utils/axios'
import urlConfig from '@/utils/urlConfig'

export default {
    // 出资人
    lenderPageApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/lender/page?pageNo='+data.pageNo+'&pageSize='+ data.pageSize,data),
    lenderListApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/lender/list',data),
    lenderDetailApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/lender/detail', data),
    lenderAddOrEditApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/lender/saveOrUpdate', data),
    lenderDeleteApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/lender/delete?id=' + data),

    //融资人
    borrowerPageApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrower/page?pageNo='+data.pageNo+'&pageSize='+ data.pageSize,data),
    borrowerDetailApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrower/detail?id=' + data),
    borrowerAddOrEditApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrower/addOrEdit', data),
    borrowerDeleteApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrower/delete?id=' + data),
    // 融资人状态查询
    borrowerRefreshContractApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrower/refreshContract?id=' + data),

    //货盘单位
    receiverFindByborrowerIdApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/receiver/findByBorrowerId?borrowerId='+data),
    receiverPageApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/receiver/page?pageNo='+data.pageNo+'&pageSize='+ data.pageSize,data),
    receiverDetailApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/receiver/detail?id=' + data),
    receiverAddOrEditApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/receiver/addOrEdit', data),
    receiverDeleteApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/receiver/delete?id=' + data),
    // 货盘单位状态查询
    receiverRrefreshContractApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/receiver/refreshContract',data ),

    //回款配置
    repayAccountFindByborrowerIdApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/repayAccount/findByBorrowerId?borrowerId='+data),
    repayAccountPageApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/repayAccount/page?pageNo='+data.pageNo+'&pageSize='+ data.pageSize,data),
    repayAccountDetailApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/repayAccount/detail?id=' + data),
    repayAccountAddOrEditApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/repayAccount/addOrEdit', data),
    repayAccountDeleteApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/repayAccount/delete?id=' + data),
    // 还款账号状态查询
    repayAccountRefreshContractApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/repayAccount/refreshContract',data ),
    // 还款账号审批
    repayAccountAddContractApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/repayAccount/addContract' , data),




    //服务费
    borrowerFeeInfoFindByborrowerIdApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrowerFeeInfo/findByBorrowerId?borrowerId='+data),
    borrowerFeeInfoPageApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrowerFeeInfo/page?pageNo='+data.pageNo+'&pageSize='+ data.pageSize,data),
    borrowerFeeInfoDetailApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrowerFeeInfo/detail?id=' + data),
    borrowerFeeInfoAddOrEditApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrowerFeeInfo/addOrEdit', data),
    borrowerFeeInfoDeleteApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrowerFeeInfo/delete?id=' + data),
    // 还款账号状态查询
    borrowerFeeInfoRefreshContractApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrowerFeeInfo/refreshContract',data ),
    getFeeInfoAgreementApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/borrowerFeeInfo/getAgreement', data),

    // 获取所有出资人
    transactionListApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/transaction/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
    transactionDetailApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/transaction/detail?txnNo=' + data),
    transactionRetryOnFailureApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/transaction/retryOnFailure?txnNo=' + data),
    transactionFinancingPayApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/transaction/financingPay', data),

    //清算还款-明细试算
    transactionSettleRepayEstApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/transaction/settleRepayEst', data),
    //清算还款-清算
    transactionSettleRepayApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/transaction/settleRepay', data),

    transactionCgListApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/trans/cg/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
    transactionCgRetryOnFailureApi: (data) => axios.apiPost(urlConfig.busiURL + '/nbi/trans/cg/retryOnFailure?txnNo=' + data),


}
