<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>贷款</span> <a-icon type="right" /> <span>贷款申请</span>
          </div>
          <!-- 隐私协议 -->
          <div style="display: flex;align-items: center;">
            <div class="agreement">
              <div class="item">
                <a-checkbox v-model="Userauthorization">
                  本人已阅读并同意
                  <a-tooltip>
                    <template slot="title"> 点击下载 </template>
                    <a-button type="link" size="small" @click="downLoadTemplate('TMPL_IND_CRD_AUTH')">{{
                      mapMapList("TMPL_IND_CRD_AUTH").state
                    }}</a-button>
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title"> 点击下载 </template>
                    <a-button type="link" size="small" @click="downLoadTemplate('TMPL_IND_INF_AUTH')">{{
                      mapMapList("TMPL_IND_INF_AUTH").state
                    }}</a-button>
                  </a-tooltip>
                </a-checkbox>
              </div>
            </div>
            <div class="right">
              <!-- 确认 -->
              <a-button type="primary" @click="confirm"> 确认 </a-button>
              <!-- 取消 -->
              <a-button @click="cancelshow = true"> 取消 </a-button>
            </div>
          </div>

        </div>
      </template>

      <!-- 个人贷款 -->
      <div class="Individualaccountopening">
        <!-- 审批详情 -->
        <div class="Approvaldetails" v-if="$route.query.status">
          <h2>审批详情</h2>
          <div class="text">
            <div class="left">状态</div>
            <div class="right">
              <!-- Loanpersonalinformation -->
              <div class="status">{{ customRender($route.query.status) }}</div>
              <a-button type="primary" v-if="$route.query.status == '04'" @click="Signacontract">
                去签约
              </a-button>
              <a-button type="primary" v-if="$route.query.status == '06'" @click="Signacontract">
                查看合同
              </a-button>
            </div>
          </div>
        </div>
        <!-- 基础信息 -->
        <div class="formbox" v-if="!$route.query.status">
          <div class="toptitle">基础信息*</div>

          <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <!-- 所属机构 -->
            <!--            <a-form-model-item class="departmentId" ref="departmentId" label="所属机构" prop="departmentId">-->
            <!--              <a-tree-select @change="departmentchange" v-model.trim="form.departmentId" style="width: 200px"-->
            <!--                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="treeData" placeholder="请选择所属机构"-->
            <!--                tree-default-expand-all>-->
            <!--              </a-tree-select>-->
            <!--            </a-form-model-item>-->

            <!-- 账号 -->
            <a-form-model-item ref="account_name" class="account_name" label="贷款申请人钱包" prop="account_name">
              <WalletChooseDialog v-model="form.account_name" :searchable="true" account-class="0" account-property="1"
                @chooseDone="onWalletChosen">
              </WalletChooseDialog>
              <WalletInfo v-if="form.account_no" account-class="0" :account-no="form.account_no"
                :account-name="form.account_name"></WalletInfo>
            </a-form-model-item>
            <!--            &lt;!&ndash; 户名 &ndash;&gt;-->
            <!--            <a-form-model-item ref="account_no" label="钱包编号" prop="account_no">-->
            <!--              <a-input v-model.trim="form.account_no" :disabled="true" placeholder="请选择钱包"></a-input>-->
            <!--            </a-form-model-item>-->

            <!-- 贷款产品 -->
            <a-form-model-item ref="account_no" class="account_no" label="贷款产品" prop="productName">
              <!--              <a-select v-model.trim="form.product_id" placeholder="请选择贷款产品" @change="product_change"
                style="width: 200px">
                <a-select-option v-for="(item, ind) in productList" :key="ind" :value="item.productId">
                  {{ item.productName }}
                </a-select-option>
              </a-select>-->
              <ProductChooseDialog v-model="form.productName" :channel-no="form.channel_no"
                :department-id="form.departmentId" product-type="1" @chooseDone="onProductChosen"></ProductChooseDialog>
            </a-form-model-item>

            <!-- 申请金额 -->
            <a-form-model-item label="申请金额" class="business_apply_sum" ref="business_apply_sum" prop="business_apply_sum">
              <a-input v-model.trim="form.business_apply_sum" type="number" placeholder="请输申请金额">
                <template #suffix>
                  <div class="yaun">万元</div>
                </template></a-input>
            </a-form-model-item>

            <!-- 申请利率 -->
            <a-form-model-item label="申请利率" class="business_rate" ref="business_rate" prop="business_rate">
              <a-input v-model.trim="form.business_rate" type="number" placeholder="请输入申请利率">
                <template #suffix>
                  <div class="yaun">%</div>
                </template></a-input>
            </a-form-model-item>

            <!-- 证件号码 -->
            <a-form-model-item disabled="disabled" label="证件号码" class="e_cert_id" prop="e_cert_id">
              <a-input v-model.trim="certnum" disabled="disabled" placeholder="请填写证件号码">
              </a-input>
            </a-form-model-item>

            <!-- 民族 -->
            <a-form-model-item label="民族" prop="nationality" class="nationality">
              <a-select v-model.trim="form.nationality" placeholder="请选择民族" style="width: 200px">
                <a-select-option v-for="item in ethnicitylist" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 学历 -->
            <a-form-model-item label="学历" prop="eduexperience" class="eduexperience">
              <a-select v-model.trim="form.eduexperience" placeholder="请选择学历" style="width: 200px">
                <a-select-option v-for="item in eduexperiencelist" :key="item.label" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 婚姻状态 -->
            <a-form-model-item label="婚姻状态" prop="marriage" class="marriage">
              <a-select v-model.trim="form.marriage" placeholder="请选择婚姻状况" style="width: 200px" @change="marriageChange">
                <a-select-option v-for="item in marriagelist" :key="item.label" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 省直辖市/自治区地区 -->
            <a-form-model-item class="region_code" ref="region_code" label="户籍/常住地市" prop="region_code">
<!--              <el-cascader v-model.trim="form.region_code" :options="citylist" placeholder="请选择所属地区"-->
<!--                :props="{ emitPath: false }" />-->
              <RegionChooseDialog
                  :channelNo="form.channel_no"
                  :appNo="app_no"
                  ref="regionChooseDialog"
                  @change="handleSelectionChange"
              />

            </a-form-model-item>
            <!-- 户籍地址  -->
            <a-form-model-item label="户籍/常住地址" class="native_place" ref="native_place" prop="native_place">
              <a-input v-model.trim="form.native_place" placeholder="请输入详细地址"></a-input>
            </a-form-model-item>

            <!-- 个人年收入 -->
            <a-form-model-item label="个人年收入" class="year_income" ref="year_income" prop="year_income">
              <a-input v-model.trim="form.year_income" type="number" placeholder="请输入个人年收入">
                <template #suffix>
                  <div class="yaun">万元</div>
                </template></a-input>
            </a-form-model-item>

            <!-- 家庭年收入 -->
            <a-form-model-item label="家庭年收入" class="family_year_income" ref="family_year_income"
              prop="family_year_income">
              <a-input v-model.trim="form.family_year_income" type="number" :min="0.12" :max="1000" placeholder="请输家庭年收入">
                <template #suffix>
                  <div class="yaun">万元</div>
                </template></a-input>
            </a-form-model-item>

            <!-- 贷款投向 -->
            <!-- <a-form-model-item label="贷款投向" class="direction" ref="direction" prop="direction">

              <a-cascader v-model.trim="form.direction" :options="industrylist" placeholder="请选择贷款投向" />


            </a-form-model-item> -->

            <!-- 经营实体 -->
            <!--            <a-form-model-item label="经营实体" class="flag" ref="flag" prop="经营实体">-->
            <!--              <a-radio-group v-model.trim="form.flag">-->
            <!--                <a-radio :value="true" > 是</a-radio>-->
            <!--                <a-radio :value="false"> 否</a-radio>-->
            <!--              </a-radio-group>-->
            <!--              <span></span>-->
            <!--            </a-form-model-item>-->



            <!-- ?经营实体 -->
            <div class="Operatingentity">经营实体（企业）
              <a-switch v-model="form.flag" @change="onChangeOperate" />
            </div>
            <!-- 证件类型 -->
            <a-form-model-item v-if="form.flag" label="证件类型" class="e_cert_type" prop="e_cert_type">
              统一社会信用代码
            </a-form-model-item>
            <!-- 名称 -->
            <a-form-model-item v-if="form.flag" label="经营企业" class="e_customer_name" prop="e_customer_name">
              <WalletChooseDialog account-property="2" account-class="0" @chooseDone="onEntWalletChosen"
                v-model="form.e_customer_name"> </WalletChooseDialog>
            </a-form-model-item>
            <!-- 证件号码 -->
            <a-form-model-item v-if="form.flag" label="证件号码" class="e_cert_id" prop="e_cert_id">
              <a-input v-model.trim="form.e_cert_id" :disabled="true" placeholder="请填写证件号码">
              </a-input>
            </a-form-model-item>
            <!-- !与经营企业关系 -->
            <a-form-model-item v-if="form.flag" label="与经营企业关系" class="whethen1" prop="whethen1"
              :rules="[{ required: form.flag, message: '请选择与经营企业关系', trigger: 'change' }]">
              <a-select v-model.trim="form.whethen1" placeholder="请选择与经营企业关系" style="width: 200px">
                <a-select-option v-for="item in whethen1list" :key="item.label" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <!-- 企业类型 -->
            <a-form-model-item v-if="form.flag" label="企业类型" class="ent_type" prop="ent_type">
              <a-select v-model.trim="form.ent_type" placeholder="请选择企业类型" style="width: 200px">
                <a-select-option v-for="item in firmEntpTp" :key="item.label" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 经营起始日 -->
            <a-form-model-item label="经营起始日" class="start_time" ref="start_time" prop="start_time">
              <a-date-picker valueFormat="YYYY-MM-DD" style="width: 200px" v-model.trim="form.start_time" />
            </a-form-model-item>
            <!-- 经营范围 -->
            <a-form-model-item :label="form.flag ? '经营范围' : '经营活动说明'" class="scope" prop="scope">
              <a-input v-model.trim="form.scope" placeholder="经营执照、挂靠说明描述等"  :maxLength="100">
              </a-input>
            </a-form-model-item>

            <!-- ?影像信息 -->
            <div class="Imageinformation">影像信息</div>
            <!-- 身份证 -->
            <!-- <a-form-model-item class="pricebox Imageinfor" ref="pricebox" label="身份证" prop="Frontofidentitycard">
              <div class="row">
               
                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">身份证人像面</span>
                  <a-upload name="files" accept="image/*" list-type="picture-card" class="avatar-uploader"
                    :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="changeIdBack">
                    <uploadImg v-show="verifyvadata.ReverseofIDcardurl" @Uploaderror="
                      verifyvadata.ReverseofIDcardloading = $event
                      " @onloadsuccsse="verifyvadata.ReverseofIDcardurl = $event"
                      @channelfileno="file_list[1].channel_file_no = $event"
                      @fileData="verifyvadata.ReverseofIDcardFile = $event" ref="IdBack">
                    </uploadImg>
                    <div v-show="verifyvadata.ReverseofIDcardloading == false &&
                      !verifyvadata.ReverseofIDcardurl
                      ">
                      <a-icon type="plus" />
                    </div>
                    <div v-if="verifyvadata.ReverseofIDcardloading &&
                      verifyvadata.ReverseofIDcardurl == ''
                      " class="example">
                      <a-spin />
                    </div>
                  </a-upload>
                  <span v-if="verifyvadata.ReverseofIDcard == false" class="erero">请先上传照片</span>
                </div>
               
                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">身份证国徽面</span>
                  <a-upload name="files" accept="image/*" list-type="picture-card" class="avatar-uploader"
                    :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="changeIdUpload">
                    <uploadImg v-show="verifyvadata.Frontofidentitycardurl" @Uploaderror="
                      verifyvadata.Frontofidentitycardloading = $event
                      " @onloadsuccsse="
    verifyvadata.Frontofidentitycardurl = $event
    " @channelfileno="file_list[0].channel_file_no = $event" @fileData="verifyvadata.FrontofidentitycardFile = $event"
                      ref="IdUp">
                    </uploadImg>
                    <div v-show="verifyvadata.Frontofidentitycardloading == false &&
                      !verifyvadata.Frontofidentitycardurl
                      ">
                      <a-icon type="plus" />
                    </div>
                    <div v-if="verifyvadata.Frontofidentitycardloading == true &&
                      verifyvadata.Frontofidentitycardurl == ''
                      " class="example">
                      <a-spin />
                    </div>
                  </a-upload>
                  <span v-if="verifyvadata.Frontofidentitycard == false" class="erero">请先上传照片</span>
                </div>

              </div>
            </a-form-model-item> -->
            <!-- 人脸识别 -->
            <!-- <a-form-model-item class="pricebox" ref="pricebox" label="正面免冠照" prop="Humanface">
              <div class="row">
             
                <div class="item">
                  <FaceCollect ref="faceCollect" :app_no="app_no" :channel_no="form.channel_no"
                    :custname="form.account_name" :id_no="certnum" @success="portraitUpload" @fail="portraitUploadFail">
                  </FaceCollect>
                 
                </div>
              </div>
             
            </a-form-model-item> -->
            <!-- 授权 -->
            <!-- <a-form-model-item class="pricebox" ref="pricebox" label="授权" prop="personagee">
              <div class="row">

                <div class="item">
                  <span style="
                      display: inline-block;
                      width: 100px;
                      margin-right: 20px;
                    ">个人征信授权</span>
                  <a-upload name="files" accept="image/*, .pdf, .doc, .docx" list-type="picture-card"
                    class="avatar-uploader" :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="personage">
                    <uploadImg v-show="verifyvadata.personageurl" @Uploaderror="verifyvadata.personageloading = $event"
                      @onloadsuccsse="verifyvadata.personageurl = $event" @fileData="verifyvadata.personageFile = $event"
                      @channelfileno="file_list[3].channel_file_no = $event" @handleFileType="handleFileType"
                      ref="personage">
                    </uploadImg>
                    <div v-show="verifyvadata.personageloading == false &&
                      !verifyvadata.personageurl
                      ">
                      <a-icon type="plus" />
                    </div>
                    <div v-if="verifyvadata.personageloading == true &&
                      verifyvadata.personageurl == ''
                      " class="example">
                      <a-spin />
                    </div>
                  </a-upload>
                  <a-button type="primary" @click="downLoadTemplate('TMPL_IND_CRD_AUTH')">下载模板</a-button>
                  <span v-if="verifyvadata.personage == false" class="erero">请先上传图片</span>
                </div>

                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">第三方数据信息查询和使用授权书</span>
                  <a-upload name="files" accept="image/*, .pdf, .doc, .docx" list-type="picture-card"
                    class="avatar-uploader" :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="Thirdparty">
                    <uploadImg v-show="verifyvadata.Thirdpartyurl" @Uploaderror="verifyvadata.Thirdpartyloading = $event"
                      @onloadsuccsse="verifyvadata.Thirdpartyurl = $event"
                      @channelfileno="file_list[4].channel_file_no = $event"
                      @fileData="verifyvadata.ThirdpartyFile = $event" ref="Thirdparty">
                    </uploadImg>
                    <div v-show="verifyvadata.Thirdpartyloading == false &&
                      !verifyvadata.Thirdpartyurl
                      ">
                      <a-icon type="plus" />
                    </div>
                    <div v-if="verifyvadata.Thirdpartyloading == true &&
                      verifyvadata.Thirdpartyurl == ''
                      " class="example">
                      <a-spin />
                    </div>
                  </a-upload>
                  <a-button type="primary" @click="downLoadTemplate('TMPL_IND_INF_AUTH')">下载模板</a-button>
                  <span v-if="verifyvadata.Thirdparty == false" class="erero">请先上传图片</span>
                </div>
              </div>
            </a-form-model-item> -->
            <!-- 经营证明 -->
            <a-form-model-item class="pricebox" ref="pricebox" label="经营证明">
              <div class="row">
                <!-- 经营证明  -->
                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">{{ form.flag ? "代付情况说明" : "经营证明" }}</span>
                  <a-upload name="files" accept="image/*, .pdf, .doc, .docx" list-type="picture-card"
                    class="avatar-uploader" :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="Certificateofoperationupload">
                    <uploadImg v-show="verifyvadata.Certificateofoperationurl" @Uploaderror="
                      verifyvadata.Certificateofoperationloading = $event
                      " @onloadsuccsse="
    verifyvadata.Certificateofoperationurl = $event
    " @channelfileno="file_list[5].channel_file_no = $event" @fileData="
    verifyvadata.CertificateofoperationFile = $event
    " ref="Certificateofoperation">
                    </uploadImg>
                    <div v-show="verifyvadata.Certificateofoperationloading == false &&
                      !verifyvadata.Certificateofoperationurl
                      ">
                      <a-icon type="plus" />
                    </div>
                    <div v-if="verifyvadata.Certificateofoperationloading == true &&
                      verifyvadata.Certificateofoperationurl == ''
                      " class="example">
                      <a-spin />
                    </div>
                  </a-upload>
                  <a-button v-if="form.flag" type="primary"
                    @click="downLoadTemplate('TMPL_IND_BUSI_PRV_ENT')">下载模板</a-button>
                  <a-button v-if="!form.flag" type="primary"
                    @click="downLoadTemplate('TMPL_IND_BUSI_PRV_NOENT')">下载模板</a-button>
                  <span v-if="verifyvadata.Certificateofoperation == false" class="erero">请先上传图片</span>
                </div>
              </div>
            </a-form-model-item>

            <!-- ?紧急联系人 -->
            <div class="mate">紧急联系人</div>
            <!-- 名称 -->
            <a-form-model-item label="联系人名称" class="relative_name" prop="relative_name">
              <a-input v-model.trim="form.relative_name" placeholder="请输入紧急联系人名字">
              </a-input>
            </a-form-model-item>
            <!-- 电话 -->
            <a-form-model-item label="联系人电话" class="relative_telephone" prop="relative_telephone">
              <a-input v-model.trim="form.relative_telephone" placeholder="请输入紧急联系人电话">
              </a-input>
            </a-form-model-item>
            <!-- 与紧急联系人关系 -->
            <a-form-model-item label="与紧急联系人关系" class="relation_ship" prop="relation_ship">
              <a-select v-model.trim="form.relation_ship" placeholder="请选择与紧急联系人关系" style="width: 200px">
                <a-select-option v-for="item in relationshiplist" :key="item.label" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!--? 配偶 -->
            <div v-if="this.form.marriage === '20'">
              <div class="mate"> 配偶 </div>

              <a-form-model-item label="配偶姓名" class="p_customer_name" prop="p_customer_name">
                <a-input v-model.trim="form.p_customer_name" placeholder="请输入配偶姓名">
                </a-input>
              </a-form-model-item>
              <!-- 电话 -->
              <a-form-model-item label="配偶电话" class="p_mobile_telephone" prop="p_mobile_telephone">
                <a-input v-model.trim="form.p_mobile_telephone" placeholder="请填写电话">
                </a-input>
              </a-form-model-item>
              <!-- 性别 -->

              <a-form-model-item label="配偶性别" class="p_sex" prop="p_sex">
                <a-radio-group v-model.trim="form.p_sex">
                  <a-radio v-for="item in sexlist" :key="item.value" :value="item.value">
                    {{ item.label }}
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>

              <!--  证件类型-->
              <!--            <a-form-model-item label="证件类型" class="p_cert_type" prop="p_cert_type">-->
              <!--              身份证-->
              <!--            </a-form-model-item>-->

              <!-- 证件号码 -->
              <a-form-model-item label="配偶身份证号" class="p_cert_id" prop="p_cert_id">
                <a-input v-model.trim="form.p_cert_id" placeholder="请填写证件号码">
                </a-input>
              </a-form-model-item>
            </div>
            <div class="mate">验证码</div>
            <!--!手机号 -->
            <a-form-model-item label="手机号" class="mobile" ref="mobile" prop="mobile">
              <a-input v-model.trim="form.mobile" type="number" placeholder="请输入手机号"></a-input>
              <div></div>
            </a-form-model-item>
            <!-- 验证码 --> <a-form-model-item label="验证码" class="code" ref="code" prop="verify_code">
              <a-input v-model.trim="form.verify_code" :max-length="6" placeholder="请输入验证码">
                <template #suffix>
                  <CountdownBtn :send-call="sendcode"></CountdownBtn>
                </template>
              </a-input>
              <div></div>
            </a-form-model-item>
          </a-form-model>
          <QRConfirm :url="qrConfirmUrl" :display.sync=qrConfirmShow busi_intro="个人授信申请"
                     :username="this.form.account_name"></QRConfirm>

        </div>
      </div>
    </a-card>
    <!-- 确认 -->
    <a-modal title="提示" class="confirmloading" :maskClosable="true" @cancel="confirmshow = false" :centered="true"
      :visible="confirmshow">
      <div class="text">
        申请贷款需要您个人征信以及第三方数据信息
        查询和使用授权，是否确认授权？确认授权后 将提交该信贷申请
      </div>
      <template v-slot:footer>
        <!-- 贷款 -->
        <div class="custom-footer">
          <a-button type="primary" @click="ConfirmSave">确认</a-button>
          <a-button @click="confirmshow = false">取消</a-button>
          <a-button @click="ConfirmQRPush">邀请扫码办理</a-button>

        </div>
      </template>
    </a-modal>
    <!-- 取消 -->
    <a-modal title="提示" class="confirmloading" :maskClosable="true" @cancel="cancelshow = false" :centered="true"
      :visible="cancelshow">
      <div class="canceltext">
        <!--个人开户  -->

        是否取消申请？取消后该页面 信息将不会被保存
      </div>
      <template v-slot:footer>
        <!-- 提现 -->
        <div class="custom-footer">
          <a-button type="primary" @click="
            cancelshow = false
          $router.go(-1)
            ">确认</a-button>
          <a-button @click="cancelshow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { BizDict, BizDictCascade } from '../../../utils/bizDict/kvMap.js'
import { validatorCardNumber } from '@/utils/validatorMethods.js'
const token = () => sessionStorage.getItem('token')
import urlConfig from '@/utils/urlConfig'

import uploadImg from '../../../components/uploadImg/index';
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";
import ProductChooseDialog from "@/view/components/ChooseDialog/ProductChooseDialog.vue";
import FaceCollect from "@/view/components/FaceCollect.vue";
import WalletInfo from '@/view/components/WalletInfo.vue'
import { contractDocTemp } from "@/utils/contractDocTemp";
import CountdownBtn from "@/view/components/CountdownBtn.vue";
import QRConfirm from "@/view/components/QRConfirm.vue";
import RegionChooseDialog from "@/view/components/ChooseDialog/RegionChooseDialog.vue";
export default {
  name: 'Personalloan',
  data() {
    return {
      qrConfirmUrl: "",
      qrConfirmShow: false,
      pdfImg: require('../../../assets/img/pdf-img.png'),
      wordImg: require('../../../assets/img/word-img.png'),
      isShowFileType: false,
      confirmshow: false,
      cancelshow: false,
      latitude: '', // 经度
      urlConfig: urlConfig,
      longitude: '', // 维度
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
      Userauthorization: false, // 用户协议是否通过

      // 民族列表
      ethnicitylist: BizDict.RaceCd,
      // 性别list
      sexlist: BizDict.GndFlg,
      // 职业列表
      OcpCdlist: BizDictCascade.OcpCd,
      // 贷款投向
      industrylist: BizDictCascade.WorkCorpIndusCD,
      // 学历列表
      eduexperiencelist: BizDict.eduexperience,
      // 婚姻
      marriagelist: BizDict.MrtlSt,
      // 城市
      citylist: BizDictCascade.DstcCd,
      // 联系人关系
      relationshiplist: BizDict.AtchRltnpTp,
      // 企业与经营实体关系
      whethen1list: BizDict.whethen1,
      // 企业类型
      firmEntpTp: [{ value: '0101', label: '法人企业', },
      { value: '0102', label: '非法人企业', },
      { value: '0103', label: '经营性个人客户', },
      { value: '0104', label: '事业单位', },
      { value: '0105', label: '社会团体', },
      { value: '0106', label: '党政机关', },
      { value: '04', label: '个体工商户', },],
      // 机构树形数组
      treeData: [],
      loading: false,
      // 客户列表
      custnumlist: [],

      // productList: [],
      // productSelected: undefined,
      account_nolist: [],
      firmlist: [], // 机构下面的企业列表
      // 控制影像是否上传
      verifyvadata: {
        // 身份证
        Frontofidentitycard: true, // 正面
        Frontofidentitycardurl: '', // 正面url
        FrontofidentitycardFile: '', // 图片数据
        Frontofidentitycardloading: false,
        ReverseofIDcard: true, // 反面
        ReverseofIDcardurl: '', // 反面
        ReverseofIDcardFile: '', // 反面
        ReverseofIDcardloading: false, // 反面

        // 人脸
        Humanface: true,
        Humanfaceurl: '',
        HumanfaceFile: '',
        Humanfaceloading: false,
        // 授权
        personage: true, // 个人
        personageurl: '', // 个人
        personageFile: '', // 个人
        personageloading: false, // 个人
        Thirdparty: true, // 第三方
        Thirdpartyurl: '', // 第三方
        ThirdpartyFile: '', // 第三方
        Thirdpartyloading: false, // 第三方
        // 经营证明
        Certificateofoperation: true, //
        Certificateofoperationurl: '', //
        CertificateofoperationFile: '', //
        Certificateofoperationloading: false, //
      },
      // 上传字段
      file_list: [
        // 身份证

        // {
        //   file_no: '',
        //   file_type: '01',
        //   channel_file_no: '',
        // },
        // {
        //   file_no: '',
        //   file_type: '02',
        //   channel_file_no: '',
        // },
        // 人脸
        // {
        //   file_no: '',
        //   file_type: '04',
        //   channel_file_no: '',
        // },
        // 个人
        // {
        //   file_no: '',
        //   file_type: '14',
        //   channel_file_no: '',
        // },
        // 第三方
        // {
        //   file_no: '',
        //   file_type: '15',
        //   channel_file_no: '',
        // },
        // 经营证明
        {
          file_no: '',
          file_type: '17',
          channel_file_no: '',
        },
      ],
      headers: {
        // "content-type":"multipart/form-data",
        token: token(),
      },

      show: true,
      Countdown: 60, // 倒计时
      // 验证参数，需要单独筛选
      certnum: '', // 验证码客户省份证号
      // cardNo: '', // 银行卡号
      app_no: '', //	应用编号
      form: {
        // 贷款产品代表
        productName: "",
        departmentId: undefined, /// 所属机构
        custnum: undefined, // /客户编号
        account_name: '', //客户名称
        channel_no: '', // 	渠道编号
        account_no: undefined, // 账号填充
        mobile: '', // 手机号
        product_id: undefined, // 产品编号
        verify_code: '', // 验证码whethen1
        nationality: '01', //民族
        eduexperience: undefined, //学历
        marriage: '10', // 婚姻状态
        region_code: "", // 所属地区

        native_place: '', //户籍地址
        year_income: '', //个人收入
        family_year_income: '', //家庭年收入
        business_apply_sum: '', //申请金额
        business_rate: '', //申请利率
        direction: 'F5219', // 贷款投向 默认
        flag: false,
        start_time: '', // 经营实体起始日期
        scope: '', // 经营范围
        // 紧急联系人
        relative_name: '', //紧急联系人名称
        relative_telephone: '', //紧急联系人电话
        relation_ship: undefined, // 与紧急联系人关系
        // 配偶
        p_customer_name: '', // 配偶姓名
        p_cert_type: 'Ind01', // 配偶证件类型身份证
        p_mobile_telephone: '', // 配偶电话
        p_sex: '', // 配偶性别
        p_cert_id: '', // 配偶证件号码
        // 经营实体
        e_cert_type: 'Ent04', //经营实体类型
        e_cert_id: '', // 经营实体证件号码
        e_customer_name: undefined, // 经营实体名称
        e_customer_num: undefined, // 经营实体客户号
        ent_type: undefined, // 企业类型
        whethen1: undefined, // 与经营企业关系
      },
      // 身份证正
      IDcardfront: '',
      // 身份证反
      IDcardback: '',
      // 人脸
      faceimage: '',
      rules: {
        departmentId: [
          { required: true, message: '请选择所属机构', trigger: 'change' },
        ],
        custnum: [
          { required: true, message: '请选择客户编号', trigger: 'change' },
        ],
        account_name: [
          { required: true, message: '请选择钱包', trigger: 'change' },
        ],
        productName: [
          { required: true, message: '请选择贷款产品', trigger: 'change' },
        ],
        channel_no: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: '手机格式不正确',
            trigger: 'blur',
          },
        ],
        // verify_code: [
        //   { required: true, message: '请输入验证码', trigger: 'blur' },
        // ],
        nationality: [
          { required: true, message: '请输入选择民族', trigger: 'change' },
        ],
        eduexperience: [
          { required: true, message: '请选择学历', trigger: 'change' },
        ],
        marriage: [
          { required: true, message: '请选择婚姻状态', trigger: 'change' },
        ],
        region_code: [
          { required: true, message: '请选择地区', trigger: 'change' },
        ],
        native_place: [
          { required: true, message: '请输入户籍地址', trigger: 'blur' },
        ],
        year_income: [
          { required: true, message: '请输入个人年收入', trigger: 'blur' },
        ],
        family_year_income: [
          { required: true, message: '请输入家庭年收入', trigger: 'blur' },
        ],
        business_apply_sum: [
          { required: true, message: '请输入申请金额', trigger: 'blur' },
        ],
        business_rate: [
          { required: true, message: '请输入申请利率', trigger: 'blur' },
        ],
        direction: [
          { required: true, message: '请输入贷款投向', trigger: 'blur' },
        ],
        e_customer_name: [
          { required: true, message: '请输入经营实体名称', trigger: 'change' },
        ],
        flag: [
          {
            required: true,
            message: '请选择是否有经营实体',
            trigger: 'change',
          },
        ],
        start_time: [
          { required: true, message: '请输入经营起始日', trigger: 'change' },
        ],
        Humanface: [{ required: true, message: '', trigger: 'blur' }],
        scope: [{ type: 'string', required: true, min: 10, message: '请输入经营范围,至少10个汉字', trigger: 'blur' }],
        Frontofidentitycard: [{ required: true, message: '', trigger: 'blur' }],

        // personagee: [{ required: true, message: '', trigger: 'blur' }],
        Certificateofoperation: [
          { required: true, message: '', trigger: 'blur' },
        ],
        // 紧急联系人
        relative_name: [
          { required: true, message: '请输入紧急联系人名字', trigger: 'blur' },
        ],
        relative_telephone: [
          { required: true, message: '请输入紧急联系人电话', trigger: 'blur' },
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: '手机格式不正确',
            trigger: 'blur',
          },
        ],
        relation_ship: [
          {
            required: true,
            message: '请选择与紧急联系人关系',
            trigger: 'blur',
          },
        ],
        p_customer_name: [
          { required: true, message: '请输入配偶姓名', trigger: 'blur' },
        ],
        // 配偶
        p_mobile_telephone: [
          { required: true, message: '请填写电话', trigger: 'blur' },
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: '手机格式不正确',
            trigger: 'blur',
          },
        ],
        p_sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        p_cert_type: [
          { required: true, message: '请选择证件类型', trigger: 'blur' },
        ],
        p_cert_id: [
          { required: true, message: '请填写证件号码', trigger: 'blur' },
          { validator: validatorCardNumber, trigger: 'blur' },
        ],
        // 经济实体
        // e_cert_type: [{ required: true, message: '请填写证件类型', trigger: 'blur' }],
        // e_cert_id: [{ required: true, message: '请填写证件号码', trigger: 'blur' }],

        // ent_type: [{ required: true, message: '请选择企业类型', trigger: 'change' }],
        // whethen1: [{ required: true, message: '请选择与经营企业关系', trigger: 'change' }],
      },
    }
  },
  components: {
    RegionChooseDialog,
    WalletChooseDialog,
    QRConfirm,
    ProductChooseDialog,
    WalletInfo,
    FaceCollect,
    uploadImg,
    CountdownBtn
  },
  async created() {
    this.$nextTick(() => {
      // this.form.departmentId = this.$session.getItem("companyId")
    })
    if (this.$route.params.type === "add") {
      // 加载选中的客户信息
      // this.onCustChosen({ custnum: this.$route.params.custnum })
      this.onWalletChosen(this.$store.state.Walletinformation)
      // TODO: 加载钱包信息
    }
  },
  mounted() {
    if (this.$route.query.status) {
      sessionStorage.setItem('msgInfo', true)
    }
  },
  methods: {
    // 处理选中变化的方法
    handleSelectionChange(zoningCode) {
      this.form.region_code = zoningCode
    },
    onChangeOperate(check) {
      if (!check) {
        // console.log('not');
        this.form.e_cert_id = ''
        this.form.e_customer_name = ''
        this.form.e_customer_num = ''
        this.form.ent_type = undefined
        this.form.scope = ''
        this.form.start_time = ''
        this.form.whethen1 = undefined
      }
    },
    mapMapList(code) {
      let obj = "";
      this.$store.state.getMapList.forEach((item) => {
        if (item.code === code) {
          obj = item;
        }
      });
      return obj;
    },
    // 状态筛选
    customRender: (text) => {
      return BizDict.LoanAppStatus.find(item => item.value === text)?.label || "---"
    },
    // 选中客户
    async onCustChosen(custChosen) {
      // 置空账号
      // this.form.account_no = ""
      // this.form.account_name = ""

      // 向后端查询并回填客户详细信息
      let res = await this.api.getCustData(custChosen.custnum)
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }

      let custInfo = res.data

      // 基本信息
      this.form.departmentId = custInfo.custPersonInfo.departmentId
      this.form.custnum = custInfo.custPersonInfo.custnum
      this.form.custname = custInfo.custPersonInfo.custname
      // this.form.eduexperience = custInfo.custPersonInfo.highestedudegreecd ? custInfo.custPersonInfo.highestedudegreecd : undefined
      this.form.nationality = custInfo.custPersonInfo.ethniccd ? custInfo.custPersonInfo.ethniccd : undefined
      this.form.marriage = custInfo.custPersonInfo.marriagesitucd
      this.form.year_income = custInfo.custPersonInfo.yearin
      this.form.family_year_income = custInfo.custPersonInfo.familyin // 个人年收

      // 证件信息
      this.certnum = custInfo.custIdInfo.certnum
      // 地址信息， 如果有的话随便找一个
      let addressInfo = custInfo.custAddressInfos
      if (addressInfo && addressInfo.length > 0) {
        this.form.region_code = addressInfo[0].distcd
        this.form.native_place = addressInfo[0].addrinfo
        await this.$refs.regionChooseDialog.selectDefault(addressInfo[0].addrinfo)
      }
    },
    async onWalletChosen(wallet) {
      let res1 = await this.api.custaccountPageApi({ pageNo: 1, pageSize: 1, accountNo: wallet.accountNo })
      if (!res1.code === 200) {
        this.$message.error("获取经营实体信息失败")
        return
      }
      let walletInfo = res1.data.records[0]

      await this.onCustChosen({custnum: walletInfo.custnum})
      this.form.account_no = walletInfo.accountNo
      this.form.account_name = walletInfo.accountName

      // 回填钱包相关信息
      this.form.mobile = walletInfo.mobile
      this.form.channel_no = walletInfo.channelNo
      this.app_no = walletInfo.appNo
      this.certnum = walletInfo.certnum
      // 判断是否有经营实体
      let result = await this.api.isBusRelatApi(this.certnum)

      // 查询钱包是否已开通白名单
      let res = await this.api.creditybmdpageApi({ pageNo: 1, pageSize: 10, accountNoSq: walletInfo.accountNo })
      if (!res.code === 200) {
        this.$message.error(res.info)
        return
      }
      if (!res.data.records?.length) {
        this.$message.warn("申请人未准入，建议先申请融资担保白名单")
      } else {
        let creditWhite = res.data.records[0]
        if (creditWhite.checkStatus !== '1') {
          this.$message.warn("申请人准入未审批，建议先联系银行进行审核")
        }
        // 回填贷款产品 担保金额 等信息
        this.form.product_id = creditWhite.productId
        this.form.productName = creditWhite.productName
        this.form.business_apply_sum = creditWhite.maxAmount / 10000
        // TODO: 查询指定贷款产品的利率
        this.form.business_rate = 8
        // 如果有经营实体就回填信息，如果没有就关闭按钮
        if (result.data) {
          if (creditWhite.relaType) {
            this.form.flag = true
            // console.log(creditWhite.relaType, 'relaType');
            await this.onEntWalletChosen({accountNo: creditWhite.accountNo})
            this.form.whethen1 = creditWhite.relaType
          }
        } else {
          // 关闭按钮，清空数据
          this.form.flag = false
          this.form.e_cert_id = ''
          this.form.e_customer_name = ''
          this.form.e_customer_num = ''
          this.form.ent_type = undefined
          this.form.scope = ''
          this.form.start_time = ''
          this.form.whethen1 = undefined
        }

      }
      // // 刷新钱包归属渠道可以使用的贷款产品
      // this.getProductList()
    },
    onProductChosen(product) {
      this.form.product_id = product.productId
      // this.form.business_apply_sum = this.form.business_apply_sum || Math.floor(product.creditLimitAmount / 10000)
      this.form.business_rate = product.rateEnd
    },
    async onEntWalletChosen(entWalletInfo) {
      let res = await this.api.custaccountPageApi({ pageNo: 1, pageSize: 1, accountNo: entWalletInfo.accountNo })
      if (!res.code === 200) {
        this.$message.error("获取经营实体信息失败")
        return
      }
      let custnum = res.data?.records[0]?.custnum
      return await this.onEntChosen(custnum)
    },
    async onEntChosen(entCustnum) {
      if (!entCustnum) {
        this.form.flag = false
        return
      }
      let res = await this.api.getEnterpriseCust(entCustnum)
      if (!res.code === 200) {
        this.$message.error("获取经营实体信息失败")
        this.form.flag = false
        return
      }
      this.form.flag = true
      let entInfo = res.data;
      this.form.e_cert_id = entInfo.custIdInfo.certnum
      this.form.e_customer_name = entInfo.custCompanyInfo.custname
      this.form.e_customer_num = entInfo.custCompanyInfo.custnum
      this.form.ent_type = "0101"
      this.form.scope = entInfo.custCompanyInfo.operbiz
      this.form.start_time = entInfo.custIdInfo.certeffectdt
    },
    // 保存
    async ConfirmSave() {
      if (this.form.verify_code == "") {
        this.$message.warning("请先填写验证码");
      }
      // 处理数据
      let data = JSON.parse(JSON.stringify(this.form))
      delete data.flag
      delete data.mobile
      delete data.productName
      // 添加影像信息的 fileno
      // this.file_list[0].file_no = this.verifyvadata.FrontofidentitycardFile
      // this.file_list[1].file_no = this.verifyvadata.ReverseofIDcardFile
      // this.file_list[2].file_no = this.verifyvadata.HumanfaceFile
      //TODO: 默认放一个文件上去， 后面自动合成的做好后去掉
      // this.file_list[3].file_no = this.verifyvadata.personageFile || "202308311902060976915307"
      // this.file_list[3].channel_file_no = this.file_list[3].channel_file_no || "202308311902060861946817"
      // this.file_list[4].file_no = this.verifyvadata.ThirdpartyFile || "202308311915290488197654"
      // this.file_list[4].channel_file_no = this.file_list[4].channel_file_no || "202308311915280876247891"
      // this.file_list[5].file_no = this.verifyvadata.CertificateofoperationFile || "202308311920530601673951"
      this.file_list[0].channel_file_no = this.file_list[0].channel_file_no || "202308311920520999193824"
      data.file_list = this.file_list.filter(f => f.file_no)
      // 添加应用编号
      data.app_no = this.app_no
      // 变换申请金额
      data.business_apply_sum = data.business_apply_sum * 10000
      // 经营范围长度
      if (data.scope.length > 200) {
        data.scope = data.scope.substring(0, 200)
      }
      const res = await this.api.creditApplyApi(data)
      if (res.code == 200) {
        // console.log(res.data);
        if (res.data.online_serial_no) {
          this.$message.success('申请成功,等待审核')
          this.confirmshow = false
          this.$router.go(-1)
        } else {
          this.$message.error("申请失败")
          let applyList = await this.api.creaditApplyInfoByCust(this.form.custnum)
          this.$message.error(applyList.data[0].statusDesc)
        }
      } else {
        this.$message.error(res.info)
      }
    },

    async ConfirmQRPush() {
      let data = JSON.parse(JSON.stringify(this.form))
      delete data.flag
      delete data.mobile
      delete data.productName
      this.file_list[0].channel_file_no = this.file_list[0].channel_file_no || "202308311920520999193824"
      data.file_list = this.file_list.filter(f => f.file_no)
      // 添加应用编号
      data.app_no = this.app_no
      // 变换申请金额
      data.business_apply_sum = data.business_apply_sum * 10000
      // 经营范围长度
      if (data.scope.length > 200) {
        data.scope = data.scope.substring(0, 200)
      }
      const res = await this.api.custOpenAccTaskSave({
        channelNo: this.form.channel_no,
        appNo:  this.app_no,
        taskType: 2,
        name: this.form.account_name,
        idNo: this.certnum,
        mobile: this.form.mobile,
        cardNo: this.form.account_no,
        reqData: data,
        exData: {
          "金额": `￥${Number(this.form.business_apply_sum).toFixed(2)}万元`,
          "利率": `年化${Number(this.form.business_rate).toFixed(2)}%`
        }
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.confirmshow = false;
      this.qrConfirmUrl = res.data
      this.qrConfirmShow = true

    },


    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.form.open_longitude = position.coords.longitude
            this.form.open_dimensions = position.coords.latitude
            // 在这里使用经纬度进行其他操作
          },
          () => {
            // 处理错误情况
            this.$message.warning('请在浏览器隐私和安全授权获取位置信息')
          }
        )
      } else {
        // 浏览器不支持Geolocation API
      }
    },

    // 选择账户
    // async account_namechange(value) {
    //   this.account_nolist.forEach((item) => {
    //     if (item.value == value.toString()) {
    //       this.form.account_name = item.account_name
    //       this.form.mobile = item.mobile
    //       this.form.channel_no = item.info.channelNo
    //
    //       // 	渠道编号
    //       this.cardNo = item.info.cardList
    //         ? item.info.cardList[0]?.bankCardNo
    //         : '' // 银行卡号
    //       this.app_no = item.info.appNo //	应用编号
    //     }
    //   })
    //   this.getProductList()
    // },
    // async getProductList() {
    //   // 选择渠道编号，拿到渠道编号，发请求获取 个人贷款的产品编号 productType 1 个人 2 企业
    //   const res = await this.api.creditProductInfolistApi({
    //     channelNo: this.form.channel_no,
    //     productType: 1,
    //   })
    //   // console.log(res)
    //   if (res.code !== 200) {
    //     this.$message.error(res.info)
    //     return
    //   }
    //   this.productList = res.data
    // },
    // 选择产品
    // product_change(value) {
    //   this.productSelected = this.productList.find(p => p.productId === value)
    //   this.form.business_apply_sum = Math.floor(this.productSelected.creditLimitAmount / 10000)
    //   this.form.business_rate = this.productSelected.rateEnd
    // },
    marriageChange() {
      // 动态改变校验规则
      let validateMate = this.form.marriage === "20"
      this.rules['p_customer_name'][0].required = validateMate
      this.rules['p_mobile_telephone'][0].required = validateMate
      this.rules['p_sex'][0].required = validateMate
      this.rules['p_cert_type'][0].required = validateMate
      this.rules['p_cert_id'][0].required = validateMate
    },
    // 发送验证码
    async sendcode() {
      // 手动触发表单校验
      var validateFieldList = []
      this.$refs.ruleForm.validateField(
        ['mobile', 'account_name'],
        async (errorMsg) => {
          if (errorMsg) {
            // 校验未通过
            // console.log(errorMsg)
          } else {
            validateFieldList.push(errorMsg)
            if (
              validateFieldList.length == 2 &&
              validateFieldList.every((item) => item == '')
            ) {
              const res = await this.api.vefifycodesend({
                appNo: this.app_no,
                channelNo: this.form.channel_no,
                mobile: Number(this.form.mobile),
                modelNo: '102001',
                accountName: this.form.account_name,
                idNo: this.certnum,
                tradeType: 9,
                cardNo: this.form.account_no, // 银行卡
              })

              if (res.code == 200) {
                // console.log('11111')
                this.$message.success('验证码发送成功')
                this.show = false
                // 60秒倒计时

                var timer = setInterval(() => {
                  this.Countdown--
                  if (this.Countdown <= 0) {
                    this.show = true
                    this.Countdown = 60
                    clearInterval(timer)
                  }
                }, 1000)
              } else {
                this.$message.error(res.msg)
              }
            }
            //之后的操作
          }
        }
      )
    },
    // 上传之前的处理
    handleBeforeUpload(file) {
      // console.log(file, 'file');
      const isImage = file.type.startsWith('image/');
      const isLt3M = file.size / 1024 / 1024 > 3
      if (isLt3M && isImage) {
        return this.compressImage(file);
      } else if (isLt3M) {
        this.$message.error('上传文件大小不能超过3M!')
        return false
      }
      return true;
    },
    async compressImage(file) {
      // console.log(file, 'compress');
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 1920; // 设置压缩后的图像宽度
            const maxHeight = 1080; // 设置压缩后的图像高度

            let newWidth = img.width;
            let newHeight = img.height;

            if (img.width > maxWidth) {
              newWidth = maxWidth;
              newHeight = (img.height * maxWidth) / img.width;
            }

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
              newWidth = (img.width * maxHeight) / img.height;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // 将压缩后的图像转换为 Blob 对象
            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], file.name, {
                  type: 'image/jpeg',
                });
                resolve(compressedFile);
              },
              'image/jpeg',
              0.9
            );
          };
        };
      });
    },
    // 上传身份证正面
    changeIdUpload(info) {
      if (this.verifyvadata.FrontofidentitycardFile !== '') {
        this.verifyvadata.Frontofidentitycardloading = false
        this.verifyvadata.FrontofidentitycardFile = ''
        this.verifyvadata.Frontofidentitycardurl = ''
      }
      if (info.file.status === 'uploading') {
        this.verifyvadata.Frontofidentitycardloading = true
        // return;
      }
      this.$refs.IdUp.handleChangeUpload(info)
    },
    // 身份证反面
    changeIdBack(info) {
      if (this.verifyvadata.ReverseofIDcardFile !== '') {
        this.verifyvadata.ReverseofIDcardloading = false
        this.verifyvadata.ReverseofIDcardFile = ''
        this.verifyvadata.ReverseofIDcardurl = ''
      }
      if (info.file.status === 'uploading') {
        this.verifyvadata.ReverseofIDcardloading = true
        // return;
      }
      this.$refs.IdBack.handleChangeUpload(info)
    },

    // 人像上传成功
    portraitUpload(data) {
      if (this.verifyvadata.HumanfaceFile !== '') {
        this.verifyvadata.Humanfaceloading = false
        this.verifyvadata.HumanfaceFile = ''
        this.verifyvadata.Humanfaceurl = ''
      }
      this.verifyvadata.HumanfaceFile = data.file_no
      this.verifyvadata.Humanfaceloading = true;
      this.file_list[2].channel_file_no = data.channel_file_no
      this.file_list[2].file_no = data.file_no
    },
    // 人像上传失败
    portraitUploadFail(data) {
      this.$message.warning("人像采集失败" + data.info)
    },
    // 授权 个人
    personage(info) {
      console.log(info, 'review');
      if (this.verifyvadata.personageFile !== '') {
        this.verifyvadata.personageloading = false
        this.verifyvadata.personageFile = ''
        this.verifyvadata.personageurl = ''
      }
      if (info.file.status === 'uploading') {
        this.verifyvadata.personageloading = true
        // return;
      }
      this.returnImg(info, 'personage')
    },
    // 第三方
    Thirdparty(info) {
      if (this.verifyvadata.ThirdpartyFile !== '') {
        this.verifyvadata.Thirdpartyloading = false
        this.verifyvadata.ThirdpartyFile = ''
        this.verifyvadata.Thirdpartyurl = ''
      }
      if (info.file.status === 'uploading') {
        this.verifyvadata.Thirdpartyloading = true
        // return;
      }
      // this.$refs.Thirdparty.handleChangeUpload(info)
      this.returnImg(info, 'Thirdparty')
    },
    // 经营证明
    Certificateofoperationupload(info) {
      if (this.verifyvadata.CertificateofoperationFile !== '') {
        this.verifyvadata.Certificateofoperationloading = false
        this.verifyvadata.CertificateofoperationFile = ''
        this.verifyvadata.Certificateofoperationurl = ''
      }
      if (info.file.status === 'uploading') {
        this.verifyvadata.Certificateofoperationloading = true
        // return;
      }
      // this.$refs.Certificateofoperation.handleChangeUpload(info)
      this.returnImg(info, 'Certificateofoperation')

    },

    // 下载模板
    async downLoadTemplate(type) {
      contractDocTemp.call(this, type, this.api, this.$message);
    },

    async downloadImage(file_no) {
      const res = await this.api.downloadImage(file_no)
      console.log(res)
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      const link = document.createElement('a');
      let Url = res.data.replace("http://", "https://");
      link.href = Url;
      link.download = 'template.xlsx'; // 文件名
      link.click();
    },
    // 点击校验
    confirm() {
      if (!this.Userauthorization) {
        this.$message.warning(
          `请先同意${this.mapMapList("TMPL_IND_CRD_AUTH").state}和${this.mapMapList("TMPL_IND_INF_AUTH").state
          }`
        );
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 进行时间校验
          this.confirmshow = true
        } else {
          this.$message.warning('请先填写贷款信息')
        }
      })
    },

    // 签约
    async Signacontract() {
      let data = {
        channel_no: this.$store.state.Loanpersonalinformation.channelNo,
        app_no: this.$store.state.Loanpersonalinformation.appNo,
        product_id: this.$store.state.Loanpersonalinformation.productId,
        online_serial_no: this.$store.state.Loanpersonalinformation.onlineSerialNo,
      }
      const res = await this.api.creditSignFlowsUrlsApi(data)
      // console.log(res);
      if (res.code !== 200) {
        this.$message.warning(res.info)
        return
      }
      window.open(res.data.recode_list[0].sign_url)
    },
    // 获取上传类型
    getFileType(file) {
      const fileType = file.type;
      if (fileType === 'application/pdf') {
        return 'PDF';
      } else if (fileType.startsWith('image/')) {
        return 'Image';
      } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return 'Word';
      } else {
        return 'Unknown';
      }
    },
    // 封装返回图片方法
    returnImg(info, name) {
      let type = this.getFileType(info.file)
      if (info.file.status === "done") {
        let imgurl = ''
        if (type === 'PDF') {
          imgurl = this.pdfImg
        } else if (type === 'Word') {
          // word
          imgurl = this.wordImg
        }
        this.$refs[name].handleChangeUpload(info, '', imgurl)
      } else if (info.file.status === "error") {
        this.$message.error("上传失败")
        this.verifyvadata.personageloading = false
      }
    },

  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>

<style lang="less" scoped>
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

/deep/.ant-modal-footer {
  width: 100%;
  border: none;
  padding-bottom: 20px;

  text-align: center;
}

/deep/.ant-card-head {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
}

/deep/.ant-modal-content {
  width: 420px;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .right {
    button {
      height: 38px;
      border: none;

      &:first-child {
        width: 108px;

        color: #ffff;
      }

      &:last-child {
        width: 108px;
        background-color: #d7d7d7;
        color: #ffff;
      }
    }
  }
}

.Individualaccountopening {
  padding-bottom: 100px;

  .Approvaldetails {
    width: 100%;
    min-height: 58px;
    margin-bottom: 25px;

    h2 {
      font-weight: 600;
    }

    .text {
      width: 100%;
      height: 44px;
      display: flex;

      .left {
        width: 227px;
        height: 44px;
        display: flex;
        align-items: center;
        color: #242424;
        padding-left: 20px;
        background-color: #f2f2f2;
      }

      .right {
        display: flex;
        align-items: center;
        padding-left: 30px;
        flex: 1;
        height: 44px;
        border-top: 1px solid #d7d7d7;
        border-bottom: 1px solid #d7d7d7;

        .status {
          display: flex;
          align-items: center;
          font-weight: 700;
          // font-size: 16px;
          height: 100%;
          margin-right: 220px;
        }
      }
    }
  }

  .toptitle {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .departmentId {
    /deep/ .ant-form-item-control-wrapper {
      border-top: 1px solid #d7d7d7;
    }
  }

  .app_no {
    // /deep/.ant-form-item-required {
    //   display: none;
    // }

    .app_nobox {
      display: flex;

      span {
        display: flex;
        width: 120px;
        align-items: center;
      }
    }

    p {
      height: 20px;
    }
  }

  /deep/ .ant-form-item-label {
    min-height: 45px;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  // .channel_no {
  //   // /deep/ .ant-form-item-label {
  //   //   padding-top: 10px;

  //   // }
  // }

  .id_type,
  .id_no,
  .job_note,
  .open_dimensions,
  .open_longitude {
    /deep/ .ant-form-item-label {
      min-height: 45px;
      padding-left: 30px;
      display: flex;
      align-items: center;
    }
  }

  /deep/ .ant-form-item:not(.code)  .ant-input-suffix {
    right: 0;
    width: 40px;

    height: 30px;
    border-radius: 0px 4px 4px 0px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
  }

  // 影像信息
  .Imageinformation,
  .mate,
  .Operatingentity {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    font-weight: 600;

    span {
      font-weight: normal;
      margin-left: 5px;
    }

    .fill {
      margin-left: 20px;
      height: 24px;
    }
  }

  .Imageinfor {
    /deep/ .ant-form-item-control-wrapper {
      border-top: 1px solid #d7d7d7;
    }
  }

  .pricebox {
    width: 100%;

    /deep/ .ant-form-item-label {
      min-height: 180px;
      padding-left: 30px;
      display: flex;
      align-items: center;
    }

    /deep/ .ant-form-item-control-wrapper {
      min-height: 180px;
    }

    .row {
      display: flex;

      .item {
        position: relative;
        margin-left: 50px;
        display: flex;
        align-items: center;
        height: 122px;



        span {
          line-height: 25px;
          text-align: center;
        }

        /deep/.ant-upload {
          width: 200px;
          height: 122px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          padding: 0px;
          overflow: hidden;

          .anticon-plus {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .erero {
          position: absolute;
          bottom: -30px;
          left: 100px;
          color: #f5222d;
        }

        .ant-upload.ant-upload-select-picture-card {
          border: 1px solid #ccc;
        }

        .ant-upload.ant-upload-select-picture-card>.ant-upload {
          padding: 0;
        }

        .ant-upload-select-picture {
          width: 200px;
          height: 122px;
          border: 0;
        }
      }
    }
  }

  /deep/ .ant-form-item {
    background-color: #f2f2f2;
    min-height: 50px;
    margin-bottom: 0px !important;
  }

  /deep/ .ant-form-item-control-wrapper {
    padding-left: 20px;
    background-color: #fff;
    border-bottom: 1px solid #d7d7d7;
    border-left: none;
    display: flex;
    min-height: 50px;
    align-items: center;

    .ant-form-item-control {
      min-width: 200px;
    }
  }
}

.confirmloading {
  color: #333333;

  .text {
    padding: 20px 10px;
    text-align: center;
    font-size: 16px;
    color: #494949;
  }

  .canceltext {
    width: 200px;
    margin: auto;
    text-align: center;
  }

  .custom-footer {
    width: 100%;

    button {
      &:first-child {
        color: #fff;
        margin-right: 80px !important;
      }

      &:last-child {
        background-color: #f2f2f2;
        color: #494949;
      }
    }
  }
}
</style>
