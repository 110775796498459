export const nbiDict = {
    statusConfig: {
        '0': {color: "blue", label: "未支付", showButton: false},
        '1': {color: "green", label: "支付成功", showButton: false},
        '4': {color: "gold", label: "部分成功", showButton: true},
        '2': {color: "red", label: "支付失败", showButton: false},
        '3': {color: "#b5651d", label: "支付超时", showButton: false},
        default: {color: "grey", label: "结果未知", showButton: true}
    },
    cgStatusConfig: {
        '0': {color: "blue", label: "未下单", showButton: true},
        '1': {color: "green", label: "下单成功", showButton: false},
        '2': {color: "red", label: "下单失败", showButton: true},
    }
};
