<script>
import WalletInfo from "@/view/components/WalletInfo.vue";
import Balance from "@/view/components/Balance.vue";
import InputBalance from "@/view/components/InputBalance.vue";
import LenderSelect from "@/view/nbi/lender/lender-select.vue";
import BorrowerSelect from "@/view/nbi/borrower/borrower-select.vue";

export default {
  components: {BorrowerSelect, LenderSelect, InputBalance, Balance, WalletInfo},
  props: {
    borrowerId: Number, // 清算时指定融资人
    repayAccountId: Number, // 可选中还款账号进行清算
    receiverId: Number, // 可选中货盘单位进行清算
  },
  emits: ['estimate'],
  data() {
    const rules = {
      repayAccountId: [
        {required: true, message: "请选择还款账户", trigger: "change"},
      ],
      receiverId: [
        {required: true, message: "请选择货盘单位", trigger: "change"},
      ],
      palletPrincipal: [
        {required: true, message: "请输入应还托盘本金", trigger: "blur"},
      ],
      palletFee: [
        // {required: true, message: "请输入应还托盘服务费", trigger: "blur"},
      ]
    }
    return {
      borrowerDetail: undefined, // 借款人详细信息
      form: {
        borrowerId: this.borrowerId,
        repayAccountId: this.repayAccountId,
        receiverId: this.receiverId,
        settleAmount: "0.00",
        palletPrincipal: "0.00", // 托盘本金
        palletFee: "0.00", // 托盘服务费
      },
      rules,
    }
  },
  async mounted() {
  },
  methods: {
    async rePickBorrower(){
      this.form.borrowerId = undefined
      this.borrowerDetail = undefined
    },
    async fetchBorrowerDetail() {
      if (!this.form.borrowerId) return
      let res = await this.api.borrowerDetailApi(this.form.borrowerId)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.borrowerDetail = {...res.data}
      await this.selectDefaultOptions()
    },
    async selectDefaultOptions() {
      // 当还款账号、收款人唯一时， 尝试默认选中
      if (this.repayAccountList.length === 1) {
        this.form.repayAccountId = this.repayAccountList[0].id
        this.fetchRepayAccountDetail()
      }
      if (this.receiverList.length === 1) {
        this.form.receiverId = this.receiverList[0].id
      }
    },
    fetchRepayAccountDetail() {
      if (this.repayAccount && this.repayAccount.extra === undefined) {
        // 在还款账号中补全手机号，供后续清算短信使用，并同步刷新余额, 只补一次
        this.fetchRepayAccountBalance()
        this.fetchRepayAccountMobile()
        this.repayAccount.extra = true
      }
    },
    async fetchRepayAccountMobile() {
      const accountNo = this.repayAccount?.accountNo
      const channelNo = this.repayAccount.channelNo
      const res = await this.api.accountqueryBalancedApi({
        channel_no: channelNo,
        account_no: accountNo,
      })
      if (res.code !== 200) {
        return this.$message.warn(res.info)
      }
      this.repayAccount.mobile = res.data.mobile
    },
    async fetchRepayAccountBalance() {
      // 先同步，再查询
      const rspSync = await this.api.clearBalanceSyncApi({id: this.repayAccount.settlingContractid})
      if (rspSync.code !== 200) return this.$message.warn(rspSync.info)
      const rspQuery = await this.api.getMchContractApi({
        pageNo: 1,
        pageSize: 1,
        contractNo: this.repayAccount.settleContractNo
      });
      if (rspQuery.code !== 200) return this.$message.warn(rspSync.info)
      this.repayAccount.settlingBalance = rspQuery.data.records[0].settling1Balance
    },
    estimate() {
      this.$emit('estimate', {
        borrowerDetail: {...this.borrowerDetail},
        repayAccount: {...this.repayAccount},
        receiver: {...this.receiver},
        ...this.form,
      })
    },
  },
  computed: {
    receiverList() {
      return (this.borrowerDetail?.receiverList ?? []).filter(item => item.status==='11')
    },
    repayAccountList() {
      return (this.borrowerDetail?.repayAccountList ?? []).filter(item => item.status)
    },
    receiver() {
      return this.borrowerDetail?.receiverList.find(item => item.id === this.form.receiverId)
    },
    repayAccount() {
      return (this.borrowerDetail?.repayAccountList ?? []).find(item => item.id === this.form.repayAccountId)
    },
    invalidMsg() {
      // 各种非表单字段级别的逻辑检查放这里
      if (!this.repayAccount) {
        return "请选择还款账号"
      }
      if (!this.receiver) {
        return "请选择货盘单位"
      }
      if (Number(this.form.palletPrincipal) < 0) {
        return "请输入本金" // 本金大于0
      }
      if (!this.form.settleAmount || Number(this.form.settleAmount) <= 0) {
        return "清算金额为0" // 本金大于0
      }
      if (Math.round(Number(this.form.palletFee) / Number(this.form.palletPrincipal) * 100) > 10) {
        return "托盘手续费过高，最多本金10%"
      }
      return undefined
    }
  }
}
</script>

<template>
  <a-card title="输入金额" style="width: 480px;min-height: 500px; height: 100%;">
    <a-form-model v-if="!borrowerDetail"  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="请选择融资人">
        <borrower-select :value.sync="form.borrowerId" @change="fetchBorrowerDetail"
                         :default-first="false"></borrower-select>
      </a-form-model-item>
    </a-form-model>
    <a-form-model v-else ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="融资人">
        <span class="float-right">
          <a-button type="link" @click="rePickBorrower" icon="reload"></a-button>
           {{ borrowerDetail.borrowerName }}
        </span>
      </a-form-model-item>
      <a-form-model-item label="出资人">
        <span class="float-right"> {{ borrowerDetail.lenderName }} </span>
      </a-form-model-item>
      <a-form-model-item label="回款账号" prop="repayAccountId">
        <a-select v-model="form.repayAccountId" placeholder="请选择回款账号" @change="fetchRepayAccountDetail">
          <a-select-option v-for="item in repayAccountList" :key="item.id"
                           :value="item.id" :disabled="!item.settleContractNo">
            <div>
              <WalletInfo :accountName="item.accountName" :accountNo="item.accountNo"
                          :show-balance="false" show-account-name :show-account-no="false"/>
              <div class="desc">
                <span>可用余额：</span>
                <Balance class="float-right" inline align-right :amount="item.settling1Balance"></Balance>
              </div>
            </div>
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="货盘单位" prop="receiverId">
        <a-select v-model="form.receiverId" placeholder="请选择货盘单位">
          <a-select-option v-for="item in receiverList" :key="item.id"
                           :value="item.id" :disabled="!(item.status==='11')">
              <span class="stretch">
                <span><WalletInfo :accountName="item.accountName" :accountNo="item.accountNo"
                                  :show-balance="false" :show-account-no="false"/>
                {{ item.receiverName || item.accountName }}
                </span>
                <span class="second"> {{ item.cgShopName }} </span>
              </span>
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="清算金额" prop="settleAmount">
        <input-balance v-model="form.settleAmount" :max="repayAccount?.settling1Balance"
                       placeholder="请输入清算金额"></input-balance>
      </a-form-model-item>
      <a-form-model-item label="托盘本金" prop="palletPrincipal">
        <input-balance v-model="form.palletPrincipal"
                       placeholder="请输入托盘本金"></input-balance>
      </a-form-model-item>

      <a-form-model-item label="托盘手续费" prop="palletFee">
        <input-balance v-model="form.palletFee" placeholder="请输入托盘本金"></input-balance>
        <a-alert v-if="invalidMsg!==undefined" :message="invalidMsg" banner/>
      </a-form-model-item>
      <div style="text-align: center;">
        <a-button @click.stop="estimate" type="primary" icon="calculator" :disabled="invalidMsg!==undefined">明细试算
        </a-button>
      </div>
    </a-form-model>
  </a-card>
</template>

<style scoped lang="less">
.desc {
  /deep/ * {
    font-size: 0.9em;
  }
}

.float-right {
  float: right;
}

.stretch {
  display: flex;
  justify-content: space-between;
}

.second {
  font-size: small;
  color: grey;
}

/deep/ .ant-form-item {
  margin-bottom: 12px;
}

/deep/ .ant-select-selection-selected-value {
  width: 100% !important;
}
</style>