<script>
import WalletInfo from "@/view/components/WalletInfo.vue";

export default {
  name: "detail-contracts",
  components: {WalletInfo},
  props: {
    borrower: Object,
    receiver: Object,
    contractList: Array,
    contractType: {
      type:String
    },
  },
  methods: {
    buildContract(notes, type, status, payerNo, payerName, payeeNo, payeeName) {
      return {notes, type, status, payerNo, payerName, payeeNo, payeeName,}
    }
  },
  computed: {
    borrow() {
      return [
        this.buildContract('借', 1, this.borrower.status,
            this.borrower.lender.accountNo, this.borrower.lender.accountName,
            this.borrower.accountNo, this.borrower.accountName,
        ),
      ]
    },

    borrow2() {
      return this.borrower?.receiverList?.map((item) => {
        return this.buildContract(  '借（受托）', 2, parseInt(item.status?.substring(1, 2) ?? "0"),
            this.borrower.accountNo, this.borrower.accountName,
            item.accountNo, item.accountName,
        )
      })
    },


    repay() {
      return [
        this.buildContract('还', 2, this.borrower.status,
            this.borrower.accountNo, this.borrower.accountName,
            this.borrower.lender.accountNo, this.borrower.lender.accountName,
        )
      ]
    },
    repay2() {
      return this.borrower?.receiverList?.map((item) => {
        return this.buildContract( '还（受托）', 2, parseInt(item.status?.substring(0,1) ?? "0"),
            item.accountNo, item.accountName,
            this.borrower.accountNo, this.borrower.accountName,
        )
      })
    },


    repayAccountList() {
      return this.borrower?.repayAccountList?.map(item => {
        return this.buildContract(`[${item.settleContract?.contractNo ?? "协议未配置"}]清分`, 1, item.status,
            item.settleContract?.accountNoSettling1 ?? "", item.settleContract?.accountNameSettling1 ?? "",
            this.borrower.accountNo, this.borrower.accountName,
        )
      })
    },

    contracts() {
      let result = [];

      if (this.contractType === 'receivers') {
        let temp = [
          ...(this.borrow2 ?? []),
          ...(this.repay2 ?? []),
          ...(this.contractList ?? []),
        ];
        result = temp.filter(item => item.payerNo === this.receiver.accountNo || item.payeeNo === this.receiver.accountNo)

      } else if (this.contractType === 'borrower') {
        result = [
          ...(this.borrow ?? []),
          ...(this.repay ?? []),
          ...(this.repayAccountList ?? []),
        ];
      }

      return result;
    },

  },
}
</script>

<template>
  <a-table :data-source="contracts" size="small" :row-key="(record)=>record.payeeNo + record.notes+ record.payerNo">
    <a-table-column title="类型" data-index="type" :width="90">
      <template slot-scope="text, record">
        <a-tag v-if="record.type === 1" color="cyan">支付协议</a-tag>
        <a-tag v-if="record.type === 2" color="orange">代扣协议</a-tag>
      </template>
    </a-table-column>
    <a-table-column title="付" data-index="payerName" align="right">
      <template slot-scope="text, record">
        <WalletInfo v-if="record.payerName" :account-name="record.payerName" :account-no="record.payerNo"
                    show-account-no
                    show-account-name></WalletInfo>
      </template>
    </a-table-column>
    <a-table-column title="说明" data-index="notes" align="center">
      <template slot-scope="text, record">

        <a-tag v-if="record.status === 1" color="green">{{ text }}
          <a-icon type="arrow-right"/>
        </a-tag>
        <a-tag v-else-if="record.status === 0" color="red">{{ text }}
          <a-icon type="arrow-right"/>
        </a-tag>
        <a-tag v-else color="grey">{{ text }}
          <a-icon type="arrow-right"/>
        </a-tag>

      </template>
    </a-table-column>
    <a-table-column title="收" data-index="payeeName" align="left">
      <template slot-scope="text, record">
        <WalletInfo :account-name="record.payeeName" :account-no="record.payeeNo" :show-balance="false" show-account-no
                    show-account-name></WalletInfo>
      </template>
    </a-table-column>
  </a-table>
</template>

<style scoped lang="less">

</style>