// interceptors.js
import axios from 'axios'
import {Loading} from 'element-ui'
import {message} from 'ant-design-vue'
import configUrl from './urlConfig'
import {router} from '@/main.js'
import {ClientJS} from 'clientjs';

let loadingInstance

const options = {text: '正在加载，请稍等...'}

const instance = axios.create({
    baseURL: configUrl.url,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
    timeout: 100000,
})

// 添加请求拦截器
instance.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        let request = config

        if ( request.url.split('?')[0] == '/business/file/manager/getAccessUrlByFileNo' ) {
            loadingInstance = Loading.service() // 发送请求时开始 loading
        } else if (request.url.split('?')[0] == '/business/base/face/photographQuery'  ) {
            loadingInstance = undefined
        } else if (request.url.split('?')[0] == '/business/cust/account/returnBillDownload'  ) {
            loadingInstance = undefined
        } else if (request.headers['No-Loading']) {
            loadingInstance = undefined
        } else {
            loadingInstance = Loading.service(options) // 发送请求时开始 loading
        }

        if ( request.url !== '/user/login' ) {
            request.headers.token = sessionStorage.getItem('token')
        }
        // 添加设备指纹
        if(!sessionStorage.getItem('fp')){
            let clientjs =new ClientJS()
            let fingerprints = clientjs.getFingerprint()
            sessionStorage.setItem('fp',fingerprints)
            request.headers.fp = fingerprints
        }
        request.headers.fp = sessionStorage.getItem('fp')
        // 在这里进行特定 URL 的判断
        if (shouldHandleRequest(request.url)) {
            processRequestData(request.data);
            // console.log(request.data);
        }
        return request
    },

    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)
// 响应拦截器
instance.interceptors.response.use(
    function (response) {
        // 对响应数据做点什么
        loadingInstance?.close() // 发送请求时结束 loading
        const data = response.data
        if (data.code === 552) {
            message.error(data.info)
            router.push('/login')
            sessionStorage.clear()
        }
        return data
    },
    function (error) {
        // 对响应错误做点什么
        loadingInstance && loadingInstance.close() // 发送请求时结束 loading
        if (error.response?.status === 401) {
            message.error('登录过期，请重新登录')
            // router.push('/login')
            sessionStorage.clear()
            window.setTimeout(()=>{
                window.location.href="/"
            },500)

        } else {
            message.error('出错啦，请稍后再试')
        }
        return Promise.reject(error)
    }
)

// 判断是否需要处理
function shouldHandleRequest(url) {
    const specialUrls = [
        '/business/busi/collect/orders/page',
        '/business/busi/payorcers/contract/page',
        '/business/mch/live/page',
        '/business/busi/oriorders/page',
        '/business/busi/payorders/page',
        '/business/cust/person/page',
        '/business/cust/company/page',
        '/business/mch/live/page',
        '/business/credit/bmd/page',
        // 添加其他需要处理的 URL
    ];

    return specialUrls.some(specialUrl => url.includes(specialUrl));
}

// 处理请求数据
function processRequestData(data) {
    const departIdCodeMapping = JSON.parse(sessionStorage.getItem('departIdCodeMapping'))

    if (data && typeof data === 'object' && departIdCodeMapping && data.departmentId) {

        const departmentCode = departIdCodeMapping[data.departmentId]
        if (departmentCode) {
            data.departmentCode = departmentCode;
            delete data.departmentId;
        }
    }
}
export default instance
