<template>
  <div>
    <a-card style="margin: 20px" v-if="borrowerDetail">
      <template #title>

        <div class="top-title">
          <div class="title-text">
            <span style="font-size: 25px">融资人:  {{ borrowerDetail.borrowerName }} </span>
          </div>
          <div class="right">
            <a-button type="normal" @click="back">返回</a-button>
          </div>
        </div>
      </template>
      <div>
        <template>
          <a-descriptions title="" :column="4" bordered size="small">
            <a-descriptions-item label="融资人">{{ borrowerDetail.borrowerName }}</a-descriptions-item>
            <a-descriptions-item label="融资账号">
              <WalletInfo v-if="borrowerDetail?.accountNo"
                          :account-no="borrowerDetail.accountNo"
                          :account-name="borrowerDetail.accountName"
                          :account-class="borrowerDetail.accountClass"
                          :show-balance="false"
              ></WalletInfo>
            </a-descriptions-item>
            <a-descriptions-item label="登记时间">{{ formattedCreateTime }}</a-descriptions-item>
            <a-descriptions-item >
              <template #label>
                状态
                <a-button v-if="borrowerDetail.status === 0" type="link" icon="reload"
                          @click="fetchBorrowerStatus" ></a-button>
              </template>
              <a-tag color="red" v-if="borrowerDetail.status === 0">未开通</a-tag>
              <a-tag color="green" v-if="borrowerDetail.status === 1">已开通</a-tag>
            </a-descriptions-item>
            <a-descriptions-item label="出资人">{{ borrowerDetail.lenderName }}</a-descriptions-item>
            <a-descriptions-item label="出资账号">
              <WalletInfo v-if='borrowerDetail?.lender?.accountNo'
                          :account-no="borrowerDetail.lender.accountNo"
                          :account-name="borrowerDetail.lender.accountName"
                          :account-class="borrowerDetail.lender.accountClass"
                          :show-balance="false"
              ></WalletInfo>
            </a-descriptions-item>
            <a-descriptions-item label="机构">
              <DeptInfo :dept-code='borrowerDetail.departmentCode'></DeptInfo>
            </a-descriptions-item>
          </a-descriptions>
        </template>
      </div>
      <div>
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="收款货盘">
            <detail-receivers :borrower="borrowerDetail" @detailModified="fetchBorrowerDetail"></detail-receivers>
          </a-tab-pane>
          <a-tab-pane key="2" tab="回款账户" force-render>
            <detail-repay-accounts :borrower="borrowerDetail"
                                   @detailModified="fetchBorrowerDetail"></detail-repay-accounts>
          </a-tab-pane>
          <a-tab-pane key="3" tab="服务费">
            <detail-fees :borrower="borrowerDetail" @detailModified="fetchBorrowerDetail"></detail-fees>
          </a-tab-pane>
          <a-tab-pane key="4" tab="协议汇总">
            <a-tabs default-active-key="4-">
              <a-tab-pane key="4-">
                <span slot="tab"><a-icon type="bank"/>{{ borrowerDetail.accountName}}</span>
                <detail-contracts :contractType="'borrower'" :borrower="borrowerDetail" :contractList="contractList"></detail-contracts>
              </a-tab-pane>

              <a-tab-pane v-for="(receiver, index) in borrowerDetail.receiverList" :key="'4-' + index">
                <span slot="tab"><a-icon type="container"/>{{ receiver.accountName }}</span>
                <detail-contracts :contractType="'receivers'" :borrower="borrowerDetail" :contractList="contractList" :receiver="receiver"></detail-contracts>
              </a-tab-pane>

            </a-tabs>

          </a-tab-pane>
          <a-tab-pane key="5" tab="往来关系图">
            <detail-graph :borrower="borrowerDetail"></detail-graph>
          </a-tab-pane>

        </a-tabs>
      </div>
    </a-card>
  </div>
</template>

<script>
import WalletInfo from "@/view/components/WalletInfo.vue";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";
import DetailReceivers from "@/view/nbi/borrower/detail/detail-receivers.vue";
import DetailRepayAccounts from "@/view/nbi/borrower/detail/detail-repay-accounts.vue";
import DetailFees from "@/view/nbi/borrower/detail/detail-fees.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";
import DetailContracts from "@/view/nbi/borrower/detail/detail-contracts.vue";
import DetailGraph from "@/view/nbi/borrower/detail/detail-graph.vue";
import moment from "moment";

export default {
  name: "borrowerDetail",
  components: {
    DetailGraph,
    DetailContracts,
    DeptInfo, DetailRepayAccounts, DetailReceivers, DetailFees, WalletChooseDialog, WalletInfo},
  data() {
    return {
      borrowerDetail: undefined,
      contractList: [],
    }
  },

  computed: {
    formattedCreateTime() {
      return this.borrowerDetail?.createTime ? moment(this.borrowerDetail?.createTime).format("YYYY-MM-DD HH:mm:ss") : "";
    }
  },
  mounted() {
    this.fetchBorrowerDetail()
  },
  methods: {
    back() {
      //回到上一页
      this.$router.go(-1)
    },

    //刷新融资人开通状态
    async fetchBorrowerStatus() {
      const res = await this.api.borrowerRefreshContractApi(this.borrowerDetail.id)
      if(res.code !== 200){
        return this.$message.error(res.info)
      }
      await this.fetchBorrowerDetail()
    },

    async fetchBorrowerDetail() {
      let id = this.$route.query.id;
      if (!id) {
        this.borrowerDetail = undefined
        return
      }
      let res = await this.api.borrowerDetailApi(id)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.borrowerDetail = {...res.data}
      const ress = await this.api.getFeeInfoAgreementApi({
        "borrowerId": this.$route.query.id
      });
      if (ress.code !== 200) {
        this.contractList = [];
      }
      this.contractList = [...ress.data]
    },
  },
}


</script>

<style scoped lang="less">
.description-container {
  display: flex;
  justify-content: center;

  .ant-descriptions-title {
    text-align: center;
  }
}

.top-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .left {
    span {
      height: 38px;
      font-size: 15px;
      font-weight: 700;
    }
  }

  .right {
    position: absolute;
    right: 0; /* 将按钮固定在最右侧 */

  }
}
img {
  cursor: pointer;
}
.ant-btn-icon-only{
  height: 22px;
}
</style>