<script>

import WalletInfo from "@/view/components/WalletInfo.vue";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";

export default {
  components: {WalletChooseDialog, WalletInfo},
  emits: ['detailModified'],
  props: {
    borrower: {
      type: Object,
      required: true
    }
  },
  data() {
    const columns = [
      {title: '货盘单位', dataIndex: 'receiverName', key: 'receiverName'},
      {
        title: '收款账户', dataIndex: 'accountNo', key: "accountNo", align: 'center', customRender: (text, record) =>
            <WalletInfo accountName={record.accountName} accountNo={text} showBalance={false}/>
      },
      {title: 'CG店铺', dataIndex: 'cgShopName', key: 'cgShopName'},
      {
        title: "状态", dataIndex: "status", key: 'status', align: "center",
        customRender: (text, record) => {
          if (text === '11') {
            return <a-tag color="green">开通</a-tag>
          } else {
            return <span><a-tag color="red">未开通</a-tag>
                    <a-button type="link" icon="reload"
                              onClick={() => this.fetchReceiverStatus(record)}
                    ></a-button>
                  </span>
          }
        },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 250,
        align: 'center',
        scopedSlots: {customRender: 'action'}
      }
    ]
    const editForm = {
      "id": undefined,
      "accountNo": undefined,
      "accountName": undefined,
      "receiverName": undefined,
      "borrowerId": undefined,
      "borrowerName": undefined,
      "lenderId": undefined,
      "lenderName": undefined,
      "cgShopId": undefined,
      "cgShopMobile": undefined,
      "cgShopName": undefined,
      "cgPayName": undefined,
      "cgPayPhone": undefined,
      "note": undefined,
    }
    const editRules = {
      accountNo: [
        {required: true, message: '请选择货盘单位钱包', trigger: 'blur'},
      ],
      receiverName: [
        {required: true, message: '请输入货盘单位名称', trigger: 'blur'},
      ],
      cgShopId: [
        {pattern: /^\d*$/, message: "请检查格式", trigger: "blur",},
      ],
      cgPayPhone: [
        {pattern: /^\d{11}$/, message: "请检查格式", trigger: "blur",},
      ]
    }
    return {
      searchForm: {},
      editDialogVisible: false,
      editCGVisible: false,
      columns,
      editForm,
      editRules,
    }
  },
  methods: {
    onDetailModified() {
      this.$emit('detailModified')
    },
    async onAddOrEdit(record) {
      if (record) {// edit
        // TODO: not implemented
        return
      }
      for (let key in this.editForm) {
        this.editForm[key] = undefined
      }
      this.editForm.borrowerId = this.borrower.id
      this.editForm.borrowerName = this.borrower.borrowerName
      this.editForm.lenderId = this.borrower.lenderId
      this.editForm.lenderName = this.borrower.lenderName
      this.editDialogVisible = true
    },
    async del(record) {
      this.$confirm({
        title: '是否确认删除?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: async () => {
          let res = await this.api.receiverDeleteApi(record.id)
          if (res.code !== 200) {
            return this.$message.error(res.info)
          }
          this.$message.success("删除成功")
          this.onDetailModified()
        },
        onCancel() {
        },
      });
    },
    onWalletChosen: async function (wallet) {
      this.editForm.accountNo = wallet.accountNo
      this.editForm.accountName = wallet.accountName
      this.editForm.receiverName = wallet.accountName
      this.editForm.accountClass = wallet.accountClass
    },
    async onSave() {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          let res = await this.api.receiverAddOrEditApi(this.editForm)
          if (res.code !== 200) {
            return this.$message.error(res.info)
          }
          this.editDialogVisible = false
          this.onDetailModified()
        }
      })
    },
    onCancel() {
      this.editDialogVisible = false
    },
    async fetchReceiverStatus(record) {
      let params = {borrowerId: this.borrower.id, receiverId: record.id}
      const res = await this.api.receiverRrefreshContractApi(params)
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      this.onDetailModified()
    },
  }
}
</script>

<template>
  <div>
    <p>
      <a-button @click="onAddOrEdit(undefined)" type="primary">新增</a-button>
    </p>
    <a-table :columns="columns" :data-source="borrower.receiverList" :row-key="(record) => record.id" size="small">
      <template slot="action" slot-scope="text, record">
        <a-button type="link" size="small" @click="del(record)" aria-label="删除">
          <a-icon type="delete" theme="twoTone" two-tone-color="red"/>
        </a-button>
      </template>
    </a-table>
    <a-modal
        title="新增货盘单位"
        :visible="editDialogVisible"
        :maskClosable="false"
        @ok="onSave"
        @cancel="onCancel"
        width="600px"
        :body-style="{ height: 'auto', overflow: 'auto' }"
    >
      <a-form-model :model="editForm" ref="editForm" :rules="editRules" :label-col="{span:6}" :wrapper-col="{span:10}">
        <a-form-model-item ref="account_name" class="account_name" label="收款人钱包" prop="accountName">
          <WalletChooseDialog v-model="editForm.accountName" :searchable="true" account-class="0"
                              :openAccountClass="true"
                              @chooseDone="onWalletChosen"/>
          <WalletInfo v-if="editForm.accountNo" :account-class="editForm.accountClass" :account-no="editForm.accountNo"
                      :account-name="editForm.accountName"/>
        </a-form-model-item>
          <a-collapse :bordered="false">
            <template #expandIcon="props">
              <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
            </template>
            <a-collapse-panel key="1" header="货盘店铺（入驻CG后配置）">
              <a-form-model-item label="店铺ID" prop="cgShopId">
                <a-input v-model="editForm.cgShopId"/>
              </a-form-model-item>
              <a-form-model-item label="店铺名称" prop="cgShopName">
                <a-input v-model="editForm.cgShopName"/>
              </a-form-model-item>
              <a-form-model-item label="店铺手机号" prop="cgShopMobile">
                <a-input v-model="editForm.cgShopMobile"/>
              </a-form-model-item>
              <a-form-model-item label="采购商名称" prop="cgPayName">
                <a-input v-model="editForm.cgPayName"/>
              </a-form-model-item>
              <a-form-model-item label="采购商手机号" prop="cgPayPhone">
                <a-input v-model="editForm.cgPayPhone"/>
              </a-form-model-item>
              <a-form-model-item label="备注" prop="note">
                <a-input v-model="editForm.note"/>
              </a-form-model-item>
            </a-collapse-panel>
          </a-collapse>
      </a-form-model>
    </a-modal>
  </div>
</template>

<style scoped lang="less">

</style>