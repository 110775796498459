import NotFound from '@/view/NotFound'
import Login from '@/view/Login'
import Home from '@/view/Home'
// 系统管理
import Authority from '@/view/System/Authority'
import Organization from '@/view/System/Organization'
import Log from '@/view/System/Log'
import Qrcode from '@/view/System/Qrcode'
import User from '@/view/System/User'
import Coreenterprise from '@/view/Base/Coreenterprise/index.vue'
import Role from '@/view/System/Role'
import Personage from '@/view/Cust/Personage'
// import addCust from '@/view/Cust/Personage/addPersonCust'
import connectCust from '@/view/Cust/Personage/connectCust'
import Enterprise from '@/view/Cust/Enterprise'
// import addEnterprise from '@/view/Cust/Enterprise/addEnterprise'
import connectEnterprise from '@/view/Cust/Enterprise/connectEnterprise'
import Accountview from '@/view/Wallet/view/Accountview.vue'
import Individualaccountopening from '@/view/Wallet/view/Individualaccountopening.vue'
import CorporateAccountOpening from '@/view/Wallet/view/CorporateAccountOpening.vue'
import openAccPersonalInfo from '@/view/Wallet/view/openAccPersonalInfo.vue'
import openaccEnterpriseInfo from '@/view/Wallet/view/openaccEnterpriseInfo.vue'
import InnerAccountOpen from '@/view/Wallet/view/InnerAccountOpen.vue'
import CorporateAccountEdit from '@/view/Wallet/Components/CorporateAccountEdit.vue'
import IndividualAccountEdit from '@/view/Wallet/Components/IndividualAccountEdit.vue'

import Accountlist from '@/view/Wallet/Accountlist/index.vue'
import SettleAccounts from "@/view/Wallet/SettleAccounts/index.vue"
import SettleAccountsDetails from "@/view/Wallet/ClearAgreementManage/settleAccountsDetails.vue"
import PaymentProtocolManage from '@/view/Orders/PaymentProtocolManage/index.vue'
import Orderentry from '@/view/Orders/Orderentry/index.vue'
import OrderDraft from '@/view/Orders/OrderDraft/index.vue'
import Originalordermanagement from '@/view/Orders/Originalordermanagement/index.vue'
import ViewOriginalOrder from '@/view/Orders/Originalordermanagement/view/ViewOriginalOrder.vue'
import OriginalOrderRevisionHistory from '@/view/Orders/Originalordermanagement/view/OriginalOrderRevisionHistory.vue'
import Summaryorder from '@/view/Orders/Summaryorder/index.vue'
import Summaryorderview from '@/view/Orders/Summaryorder/view/Summaryorderview.vue'
import Summarizeorderrevisionrecords from '@/view/Orders/Summaryorder/view/Summarizeorderrevisionrecords.vue'
import Liquidationandrepayment from '@/view/Orders/Summaryorder/view/Liquidationandrepayment.vue'
import AddPayment from '@/view/Orders/PaymentProtocolManage/AddPayment/index.vue'
import PaymentOrders from '@/view/Orders/PaymentOrders/index.vue'
import AddPaymentOrders from '@/view/Orders/PaymentOrders/AddPaymentOrders/index.vue'
import loan from '@/view/FinancingManagement/loan/index.vue'
import Personalloan from '@/view/FinancingManagement/Personalloan/index.vue'
import Financingwhitelist from '@/view/FinancingManagement/Financingwhitelist'
import Enterpriseloan from '@/view/FinancingManagement/Enterpriseloan/index.vue'
import GuarantorManage from "@/view/FinancingManagement/GuarantorManage/index.vue"

import MchManage from '@/view/Mch/MchManage/index.vue'
import AddMchManage from '@/view/Mch/MchManage/AddMchManage/index.vue'
import MchShopInvoice from '@/view/Mch/MchShopInvoice/index.vue'
import MchShopInvoiceAdd from '@/view/Mch/MchShopInvoice/add.vue'
import MchShopInvoiceNormal from '@/view/Mch/MchShopInvoice/normal.vue'
import MchShopInvoiceDepositImport from '@/view/Mch/MchShopInvoice/deposit/import.vue'
import MchShopManage from '@/view/Mch/MchShopManage/index.vue'
import AddMchShopManage from '@/view/Mch/MchShopManage/AddMchShopManage/index.vue'
import ClearAgreementManage from '@/view/Wallet/ClearAgreementManage/index'
import MchWithDrawal from '@/view/Mch/MchWithDrawal/index.vue'
import CheckList from '@/view/Mch/CheckList/index.vue'
import Successfulsigning from '@/view/Successfulsigning/index.vue'

import payWithhold from '@/view/Orders/payWithhold/index.vue'
import payWithholdAdd from '@/view/Orders/payWithhold/add.vue'


//案例
// import Example from '@/view/Example'
//基础管理
import Configparam from '@/view/Base/Configparam'
import Loanproduct from '@/view/Base/loanproduct/index.vue'
import Salesplatform from '@/view/Base/Salesplatform/index.vue'
import Filestorageinformation from '@/view/System/Filestorageinformation/index.vue'
import Filestoragemode from '@/view/Base/Filestoragemode/index.vue'
import Shortmessagerecord from '@/view/System/Shortmessagerecord/index.vue'
import Smstemplate from '@/view/Base/Smstemplate/index.vue'
import Servicechargeallocation from '@/view/Base/Servicechargeallocation/index.vue'

// 驾驶舱
import Cockpit from '@/view/Cockpit/index.vue'
import CoreCompany from '@/view/Cockpit/CoreCompany.vue'
import FinancingEco from '@/view/Cockpit/FinancingEco.vue'
import AgentIncome from '@/view/Cockpit/AgentIncome.vue'
// 社会资金代销
import lender from '@/view/nbi/lender/index.vue'
import borrower from '@/view/nbi/borrower/index.vue'
import borrowerDetail from '@/view/nbi/borrower/detail'
import advance from "@/view/nbi/advance/index.vue";
import advanceAdd from "@/view/nbi/advance/add.vue";
import reclaim from "@/view/nbi/reclaim/index.vue";
import reclaimSettle from "@/view/nbi/reclaim/settle/index.vue";
import cg from "@/view/nbi/cg/index.vue";



export default [
  // 系统管理
  { path: '/system/user', component: User, meta: '用户管理' },
  { path: '/system/role', component: Role, meta: '角色管理' },
  { path: '/system/authority', component: Authority, meta: '权限管理' },
  { path: '/system/organization', component: Organization, meta: '机构管理' },
  { path: '/system/filestorageinformation', component: Filestorageinformation, meta: '文件存储信息' },
  { path: '/system/shortmessagerecord', component: Shortmessagerecord, meta: '短信记录' },
  { path: '/system/log', component: Log, meta: '日志管理' },
  { path: '/system/qrcode', component: Qrcode, meta: '二维码业务' },

  // { path: '/example', component: Example, meta: '案例' },
  {path: '/cust/personage',name :'Personage' ,component: Personage, meta: '个人客户管理'},
  // {path: '/cust/personage/addCust', component: addCust, meta: '新增编辑个人客户'},
  {path: '/cust/personage/addCust', name: 'addCust', component: () => import('@/view/Cust/Personage/addPersonCust'), meta: '新增个人客户',},
  {path: '/cust/personage/connectCust', component: connectCust, meta: '关联用户'},
  {path: '/cust/enterprise', name: 'Enterprise',component: Enterprise, meta: '企业客户管理'},
  // {path: '/cust/enterprise/addEnterprise', component: addEnterprise, meta: '企业客户新增'},
  {path: '/cust/enterprise/addEnterprise', name: 'addEnterprise', component: () => import('@/view/Cust/Enterprise/addEnterprise'), meta: '企业客户新增',},
  {path: '/cust/enterprise/connectEnterprise', component: connectEnterprise, meta: '企业客户关联用户'},
  // {path: '/wallet/accountmanagement', component: accountmanagement, meta: '账户管理'},
  { path: '/wallet/accountview', component: Accountview, meta: '账户查看' },
  { path: '/wallet/accountlist',name:'accountList', component: Accountlist, meta:'账户列表'},
  {path: '/wallet/settleAccounts', component: SettleAccounts, meta: '待清算账户'},
  {path: '/wallet/Individualaccountopening', name:"Individualaccountopening", component: Individualaccountopening, meta: '个人开户'},
  { path: '/wallet/CorporateAccountOpening', name: "CorporateAccountOpening", component: CorporateAccountOpening, meta: '企业开户' },
  { path: '/wallet/openAccPersonalInfo', name: "openAccPersonalInfo", component: openAccPersonalInfo, meta: '个人开户信息详情' },
  { path: '/wallet/openaccEnterpriseInfo', name: "openaccEnterpriseInfo", component: openaccEnterpriseInfo, meta: '企业开户信息详情' },
  { path: '/wallet/InnerAccountOpen', name: "InnerAccountOpen", component: InnerAccountOpen, meta: '内部户开户' },
  { path: '/wallet/CorporateAccountEdit', name: "CorporateAccountEdit", component: CorporateAccountEdit, meta: '企业账户修改' },
  {path: '/wallet/IndividualAccountEdit', name:"IndividualAccountEdit", component: IndividualAccountEdit, meta: '个人账户修改'},
  {path: '/wallet/ClearAgreementManage', name: 'ClearAgreementManage', component: ClearAgreementManage, meta: '清分协议管理' },
  {path: '/orders/PaymentProtocolManage', name:"PaymentProtocolManage", component: PaymentProtocolManage, meta: '支付协议管理'},
  {path: '/orders/Orderentry', name: 'Orderentry', component:Orderentry, meta: '订单导入'},
  {path: '/orders/OrderDraft', name: 'OrderDraft', component:OrderDraft, meta: '订单录入'},
  {path: '/orders/Originalordermanagement', name: 'Originalordermanagement', component: Originalordermanagement, meta: '原始订单管理'},
  {path: '/orders/ViewOriginalOrder', name: 'ViewOriginalOrder', component: ViewOriginalOrder, meta: '原始订单查看'},
  {path: '/orders/OriginalOrderRevisionHistory', name: 'OriginalOrderRevisionHistory', component: OriginalOrderRevisionHistory, meta: '原始订单查看'},
  {path: '/orders/Summaryorder', name: 'Summaryorder', component: Summaryorder , meta: '汇总订单'},
  {path: '/orders/Summaryorderview', name: 'Summaryorderview', component:Summaryorderview, meta: '汇总订单查看'},
  {path: '/orders/Summarizeorderrevisionrecords', name: 'Summarizeorderrevisionrecords', component: Summarizeorderrevisionrecords, meta: '汇总订单修订记录'},
  {path: '/orders/Liquidationandrepayment', name: 'Liquidationandrepayment', component: Liquidationandrepayment, meta: '清算还款'},
  {path: '/orders/PaymentProtocolManage/AddPayment', name: 'AddPayment', component:AddPayment, meta: '支付协议管理-新增'},
  {path: '/orders/PaymentOrders', name: 'PaymentOrders', component: PaymentOrders, meta: '订单支付'},
  {path: '/orders/PaymentOrders/AddPaymentOrders', name: 'AddPaymentOrders', component: AddPaymentOrders , meta: '新增支付'},
  {path: '/Financingmanagement/loan', name: 'loan', component: loan, meta: '贷款申请' },
  { path: '/Financingmanagement/Financingwhitelist', name: 'Financingwhitelist', component: Financingwhitelist, meta: '融资白名单' },
  {path: '/Financingmanagement/GuarantorManage', name: 'GuarantorManage', component: GuarantorManage , meta: '担保人管理'},
  {path: '/Financingmanagement/Personalloan', name: 'Personalloan', component:Personalloan, meta: '个人贷款'},
  {path: '/Financingmanagement/Enterpriseloan', name: 'Enterpriseloan', component: Enterpriseloan , meta: '企业贷款'},
  {path: '/Mch/MchManage', name: 'MchManage', component: MchManage, meta: '电商管理'},
  {path: '/Mch/MchManage/addMchManage', name: 'AddMchManage', component: AddMchManage, meta: '新增电商管理'},
  {path: '/Mch/MchShopInvoice', name: 'MchShopInvoice', component: MchShopInvoice, meta: '店铺发票管理'},
  {path: '/Mch/MchShopInvoiceAdd', name: 'MchShopInvoiceAdd', component: MchShopInvoiceAdd, meta: '店铺发票管理新增'},
  {path: '/Mch/MchShopInvoiceNormal', name: 'MchShopInvoiceNormal', component: MchShopInvoiceNormal, meta: '店铺发票管理匹配'},
  {path: '/Mch/MchShopInvoiceDepositImport', name: 'MchShopInvoiceDepositImport', component: MchShopInvoiceDepositImport, meta: '店铺发票到账流水导入'},
  {path: '/Mch/MchShopManage', name: 'MchShopManage', component: MchShopManage, meta: '店铺管理' },
  { path: '/Mch/MchShopManage/AddMchShopManage', name: 'AddMchShopManage', component: AddMchShopManage, meta: '新增店铺管理' },
  {path: '/Mch/settleAccountsDetails', component: SettleAccountsDetails, meta: '清分协议详情'},
  {path: '/Mch/MchWithDrawal', name: 'MchWithDrawal', component: MchWithDrawal, meta: '电商提现管理'},
  {path: '/Mch/CheckList', name: 'CheckList', component: CheckList, meta: '待审批事项'},

  {path: '/orders/payWithhold', name: 'payWithhold', component: payWithhold, meta: '归集协议管理'},
  {path: '/orders/payWithholdAdd', name: 'payWithholdAdd', component: payWithholdAdd, meta: '归集协议管理新增'},



  {path: '/base/configparam', component: Configparam, meta: '参数配置'},
  {path: '/base/loanproduct', component: Loanproduct, meta: '贷款产品'},
  {path: '/base/salesplatform', component: Salesplatform, meta: '销售平台'},
  {path: '/base/servicechargeallocation', component: Servicechargeallocation, meta: '服务费'},
  { path: '/base/coreenterprise', component: Coreenterprise, meta: '核心企业' },
  { path: '/base/smstemplate', component: Smstemplate, meta: '短信模版' },
  { path: '/base/filestoragemode', component: Filestoragemode, meta: '文件存储方式' },

  // 其他
  { path: '/home', component: Home, meta: '首页' },
  { path: '/login', component: Login, meta: '登录' },
  { path: '/cockpit', component: Cockpit, meta: '驾驶舱' },
  { path: '/cockpit/coreCompany', component: CoreCompany, meta: '核心企业' },
  { path: '/cockpit/FinancingEco', component: FinancingEco, meta: '融资电商' },
  { path: '/cockpit/AgentIncome', component: AgentIncome, meta: '代理商收入' },

  { path: '/nbi/lender', component: lender, meta: '出资人' },
  { path: '/nbi/borrower', component: borrower, meta: '融资人' },
  { path: '/nbi/borrower/detail', component: borrowerDetail, meta: '融资人详情' },
  { path: '/nbi/advance', component: advance, meta: '垫资记录' },
  { path: '/nbi/advance/add', name: "advanceAdd", component: advanceAdd, meta: '垫资' },
  { path: '/nbi/trans/record', component: () => import('@/view/nbi/trans/record.vue'),  meta: '交易记录' },
  { path: '/nbi/trans/detail', component: () => import('@/view/nbi/trans/detail.vue'),  meta: '交易明细' },
  { path: '/nbi/reclaim', component: reclaim, meta: '清算记录' },
  { path: '/nbi/reclaim/settle', name: "reclaimSettle", component: reclaimSettle, meta: '清算' },
  { path: '/nbi/cg', component: cg, meta: 'cg' },

  {path: '/selfAccoutOpen', name: 'selfAccoutOpen', component: () => import('@/extra/selfAccoutOpen.vue'),},
  {path: '/selfEntAccOpen', name: 'selfEntAccOpen', component: () => import('@/extra/selfEntAccOpen.vue'),},
  {path: '/confirm', name: 'confirm', component: () => import('@/extra/Confirm.vue'),},

 // 签约成功
 { path: '/successfulsigning', component: Successfulsigning, meta: '签约成功' },
  { path: '/404', component: NotFound, meta: '404' },
  { path: '/', component: Home, meta: '首页' },
  { path: '*', redirect: '/404' },
]
