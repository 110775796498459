import instance from './request';
import jsonPoly from './jsonPoly';

export default {
  apiGet(url, params = {}) {
    return instance({
      method: 'get',
      url,
      params,
    });
  },
  apiPost(url, data = {},noloading=false) {
    let headers = {}
    if(noloading){
      headers['No-Loading'] ='True'
    }
    return instance({
      method: 'post',
      url,
      headers: headers,
      data: jsonPoly.clearEmptyParam(data),
    });
  },
  apiDownload(url, data = {}, headers={}) {
    return instance({
      method: 'post',
      url: url,
      headers,
      data: jsonPoly.clearEmptyParam(data),
    });
  },
  apiDown(url, params = {}) {
    return instance({
      method: 'get',
      url,
      params,
      responseType: 'blob',
    });
  },
  apiPostDown(url, data = {}) {
    return instance({
      method: 'post',
      url,
      data: jsonPoly.clearEmptyParam(data),
      responseType: 'blob',
    });
  },
  apiFormPost(url, formData) {
    return instance({
      method: 'post',
      url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  },

};
